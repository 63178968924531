.edit-question-modal {
  .select__wrapper {
    margin-bottom: 24px;
  }
  &__buttons {
    display: flex;
    gap: 8px;
    margin-top: 40px;
    .btn {
      height: 40px;
    }
  }
  &__form {
    max-width: 400px;
    width: 100%;
    > .input__wrapper:first-child {
      .input {
        margin-bottom: 32px;
      }
      .input__error {
        bottom: 18px;
      }
    }
    textarea {
      height: 200px;
    }
  }
  &__header {
    font-weight: 700;
    font-size: 20px;
    line-height: 126.9%;
    color: $navy700;
    margin-bottom: 40px;
  }
  
}
