.task-list {
  &__content {
    padding: 0 0 0 66px;
    max-height: 0;
    overflow: hidden;
    transition: 0.3s;

    &--active {
      transition: 0.3s;
      padding: 0 0 40px 66px;
      max-height: 2000px;
      @include mobile-only {
        padding-bottom: 26px;
      }
    }
    &--drag {
      margin-top: 70px !important;
    }
  }
  &__add-task {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 24px;
    .btn {
      max-height: 40px;
      margin: 0 !important;
      @include mobile-only {
        max-width: 100% !important;
        width: 100%;
      }
    }
  }
}