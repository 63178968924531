@import './components/Bar.scss';

.my-profile {
  &__title {
    color: rgba(251, 251, 255, 1);
    font-size: 20px;
    font-family: Jost;
    font-weight: 700;
    line-height: 126.9%; 
    margin-bottom: 8px;
  }
  &__subtitle {
    color: #EBEBFF;
    font-size: 12px;
    font-family: Jost;
    line-height: 126.9%;
  }
  &__header {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 17px 20px 17px;
    overflow: visible;
    &--radius {
      height: 10px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      width: 100%;
      background-color: #fafaff;
      position: absolute;
      left: 0;
      bottom: 0;
    }
    @include lg {
    padding: 0 70px;
    align-items: center;
    }
    .dashboard__container {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0;
      overflow: visible;
      @include lg {
        align-items: center;
        padding: 0 15px 0 0;
      }
    }
  }
  &__account-type {
    margin-right: auto;
    color: $navy600;
    font-size: 12px;
    font-family: Jost;
    font-weight: 500;
    line-height: 126.9%; 
    padding: 4.5px 19px;
    background-color:$purple400;
    border-radius: 100px;
    margin-bottom: 17px;
    margin-left: 92px;
    display: none;
    @include lg {
      display: block;
    }
  }
  &__account-type-mobile {
    display: flex;
    margin-top: 19px;
    div {
      color: $navy600;
      font-size: 12px;
      font-family: Jost;
      font-weight: 500;
      line-height: 126.9%; 
      padding: 4.5px 19px;
      background-color:$purple400;
      border-radius: 100px;
    }
    @include lg {
      display: none;
    }
    
  }
  &__avatar {
    margin-bottom: -65px;
    position: relative;
    z-index: 999;
    @include lg {
      margin-bottom: unset;
    }
    &--edit {

      &:after {
        content: '';
        background-color: #bbb8f4;
        width: 28px;
        height: 28px;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 50%;
      }
    }
  }
  &__avatar-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    padding: 1px;
    object-fit: cover;
    background-color: #fff;
  }
  &__edit-avatar {
    position: absolute;
    top: -32px;
    right: 7px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    width: 16px;
    path {
      fill: rgba(32, 32, 63, 1);
    } 
  }
  &__content-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 126.9%;
    color: $navy500;
    margin-top: 24px;
    margin-bottom: 16px;
  }
  &__list-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 126.9%;
    margin-bottom: 15px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 133px;
    padding-top: 12px;
    li {
      display: flex;
      align-self: center;
      p {
        display: flex;
        align-self: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 1;
        margin-left: 8px;
      }
    }
  }
  &__content {
    // overflow: auto;
    z-index: 2;
    @include mobile-only {
      z-index: 0;
    }
    @include lg {
      padding: 8px 70px;
      display: flex;
      height: 100%;
      justify-content: space-between;
    }
    &--column {
      @include lg {
        width: 100%;
        &:nth-child(1n) {
          margin-right: 16px;
        }
      }
    }
  }
}
