@import './components/SingleCategory.scss';

.select-category {
  margin-top: 32px;
  overflow-y: auto;
  @include lg {
    max-width: 342px;
    max-height: 350px;
    margin-bottom: 24px;
  }
  &__wrapper {
    @include mobile-only {
      .login-page__body {
        // max-height: calc(100% - 230px);
        // padding-bottom: 30px;
        padding: 0;
        margin: 0;
      }
    }
  }
}
