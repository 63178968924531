.login-screens {
  display: flex;
  height: 100%;
  width: 100%;
  @include mobile-only {
    flex-direction: column-reverse;
  }

  &__left {
    background: #ffffff;
    box-shadow: 16px 0px 40px rgba(0, 0, 0, 0.2);
    flex: 604px 0 0;
    display: flex;
    position: relative;
    @include mobile-only {
      flex: calc(100% - 149px);
      border-radius: 10px 10px 0px 0px;
      z-index: 1;
    }
  }

  &__list {
    @include mobile-only {
      display: none;
    }
  }
  &__title {
    @include mobile-only {
      display: none;
    }
  }

  &__right {
    flex: 1;
    background: linear-gradient(14.59deg, #03041a 0%, #222450 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    @include mobile-only {
      padding-bottom: 20px;
      margin-bottom: -20px;
      flex: unset;
      height: 149px;
      flex-shrink: 0;
    }
  }

  &__logo {
    height: 81px;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 125%;
    transform: rotate(0.1deg);
    color: $purple300;
    margin-top: 85px;
    margin-bottom: 35px;
    padding-right: 5px;
  }

  &__right-container {
    max-width: 323px;
  }

  &__list-item {
    color: $purple200;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    align-items: center;
    .svg {
      margin-right: 14px;
      min-width: 24px;
    }
    margin-bottom: 16px;
  }

  &__animated-element {
    opacity: 0.5;
    filter: blur(60px);
    transform: rotate(17.45deg);
    position: absolute;
    width: 595.5px;
    height: 595.5px;
    display: flex;
    flex: 1;
    &--first {
      animation: animatied-first 15s infinite;
      transform: rotate(99deg);
      animation-timing-function: ease-in-out;
    }
    &--second {
      animation: animatied-second 25s infinite;

      transform: rotate(222deg);
    }
    @include mobile-only {
      display: none;
    }
  }

  &__logo {
    @include mobile-only {
      max-height: 37px;
      width: 124px;
    }
  }
}

@keyframes animatied-first {
  0% {
    left: 20%;
    bottom: 0%;
  }
  25% {
    left: 30%;
    bottom: -15%;
  }
  50% {
    left: 80%;
    bottom: -30%;
  }
  75% {
    left: 30%;
    bottom: -15%;
  }
  100% {
    left: 20%;
    bottom: 0%;
  }
}

@keyframes animatied-second {
  0% {
    left: 0%;
    top: 0%;
  }
  25% {
    left: 30%;
    top: -5%;
  }
  50% {
    left: 50%;
    top: -15%;
  }
  75% {
    left: 30%;
    top: -5%;
  }
  100% {
    left: 0%;
    top: 0%;
  }
}
