.select {
  &__indicators {
    margin-right: 6px;
  }
  &__option-container {
    display: flex;
    max-height: 28px;
    height: 21px;
    gap: 15px;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: $navy400;
  }
  div &__multi-value__remove {
    cursor: pointer;
    padding: 0;
    margin-left: 5px;
    svg {
      width: 16px;
      height: 16px;
      fill: $gray-6;
    }
  }
  div &__multi-value {
    border-radius: 20px;
    padding: 4px 5px 4px 10px;
    margin: 0;
    display: flex;
    min-width: 0;
    background-color: hsl(0, 0%, 90%);
    box-sizing: border-box;
    span {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: $black;
    }
    .svg {
      width: 16px;
      height: 11px;
    }
    &__label {
      padding: 0;
      display: flex;
    }
    .select__option-container {
      height: unset;
      gap: 8px;
    }
  }
  div &__control {
    cursor: pointer;
  }
  div &__menu {
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.12);
    margin-top: 5px;
  }
  div &__menu-list {
    padding: 0;
    border-radius: 4px;
    max-height: 336px;
  }
  div &__option {
    padding: 0 17px;
    display: flex;
    align-items: center;
    height: 48px;
    cursor: pointer;
    background-color: $white;
    &:hover {
      background-color: $background1;
    }
    &--is-selected {
      background-color: $gray-5;
    }
    &:active {
      background-color: $gray-5;
    }
  }
  &__indicator {
   svg path {
    stroke: $purple400 !important;
   }    
  }
  &__indicator-separator {
    display: none;
  }
  div &__input-container {
    margin: 0;
  }
  div &__value-container {
    padding: 0px 13px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    height: 48px;
    &--is-multi {
      gap: 5px;
    }
  }
  div &__control {
    border: none;
    background: #eeedff;
    box-shadow: none;
  }
  &__label {
    font-weight: 500;
    font-size: 14px;
    line-height: 126.9%;
    color: $navy600;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
  }
  &__add-more {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: $green;
    cursor: pointer;
  }
  &__optional {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: $gray-1;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 100%;
    position: relative
  }
  div &__placeholder {
    color: $gray-2;
  }
  div &--alone {
    .select__multi-value {
      background-color: transparent;
      padding: 0;
    }
    .select__multi-value__label {
      font-size: 100%;
    }
    .select__multi-value span {
      font-size: 14px;
      line-height: 17px;
    }
    .select__multi-value .svg {
      width: auto;
      height: 21px;
    }
    .select__multi-value__remove {
      display: none;
    }
    .select__value-container--is-multi + .select__indicators {
      display: flex;
    }
  }
}

.css-b62m3t-container {
  margin-bottom: 22px;
}

.select__option {
  color: $navy500 !important;
  font-family: 'Jost' !important;
  line-height: 20px !important;
  // margin: 10px 0 !important;
  min-height: 55px !important;
  max-height: 100px !important;
  height: auto !important;
  display: flex !important;
  font-size: 14px !important;
  a, div {
    padding: 10px !important;
    padding-left: 0 !important;
    min-height: auto !important;
    max-height: 100px !important;
    height: 100%;
  }
}
.select__multi-value {
  padding: 5px 10px 5px 16px !important;
  background-color: $purple200 !important;
  .select__multi-value__label .select__option-container span{
    color: $navy400;
    font-family: Jost;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 126.9%; /* 17.766px */ 
  }
  .select__multi-value__remove {
    svg path {
      fill: $navy200;
    }
  }
}
.select__placeholder {
  color: rgb(178, 179, 216) !important;
}

.css-1xc3v61-indicatorContainer, .css-15lsz6c-indicatorContainer {
  transition: 0.3s;
  svg path {
    fill: $purple400 !important;
    transition: 0.3s;
   } 
   &:hover {
    svg path {
      fill: $purple600 !important;
      transition: 0.3s;
     } 
   }
}

.css-1dimb5e-singleValue {
  min-width: 100px;
}