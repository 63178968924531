.single-category {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  padding: 17px 28px;
  background: $purple300;
  border-radius: 8px;
  margin-bottom: 8px;
  font-weight: 600;
  transition: 0.3s;
  cursor: pointer;
  .svg path {
    transition: 0.3s;
    // opacity: 0;
    fill: $navy600;
  }
  &--selected {
    background: $navy600;
    color: #ebebff;
    .svg path {
      fill: $purple300;
      // opacity: 1;
    }
  }
}
