.lesson-page__header {
  @include mobile-only {
    height: 70px;
  }
  &--title {
    margin-top: auto;
  }
  &--container {
    margin-top: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    padding: 0 16px;
  }
  &--lesson-number {
    color: #FBFBFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 126.9%; 

  }
  &--bottom {
    margin-top: auto;
    margin-bottom: 26px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &--close {
    color: $purple400;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 126.9%; 
    cursor: pointer;
    margin-left: auto;
  }
}