.exam-call-to-action {
  width: 100%;
  background-color: $purple300;
  margin-bottom: 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  .star {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    margin-right: 16px;
  }
  &__paragraph {
    color: $navy400;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.14px; 
    cursor: pointer;
    strong {
      color:$purple500;
    margin-left: 5px;

    }
    .svg {
      margin-bottom: -6px;
      margin-left: -2px;
    }
  }
}