.all-cities-page {
  &__table {
    width: 100%;
    border-collapse: initial;
    border-spacing: 0 8px;
  }
  &__thead {
    color: #393C73;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 126.9%; 
    height: 30px;
    th {
      text-align: left;
      
    }
    @include mobile-only {
      display: none;
    }
  }
  &__cell {
    background-color: rgba(240, 239, 255, 1);
    padding: 19px 0;
    color: #393C73;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 126.9%; 
    &--number {
      padding-left: 25px;
      width: 80px;
      border-radius: 8px 0 0 8px;
      text-align: left;
    }
    &--cities {
      text-align: left;
      width: 60%;
    }
    &--price {
      width: 20%;
    }
    &--percentage {
      width: 15%;
    }
    &:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
}