.save-tasks-empty-state {
  display: flex;
  justify-content: center;
  margin-top: 128px;
  @include mobile-only {
    margin-top: 40px;
  }
  &__content {
    max-width: 532px;
  }
  &__heading {
    font-size: 80px;
    text-align: center;
  }
  &__description {
    color: $navy500;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 138%;
    letter-spacing: -0.18px; 
    margin-bottom: 60px;
    margin-top: 16px;
  }
  .btn {
    max-height: 48px;
    max-width: 296px;
    margin: 0 auto;
  }
}