.add-user-modal {
  .edit-user-modal__form > .input__wrapper:first-child {
    .input {
      margin-bottom: 32px;
    }
    .input__error {
      bottom: 18px;
    }
  }
  .drop-file-input__wrapper {
    margin-bottom: 34px;
  }
}
