.examinationPage {
  width: 100%;
  position: relative;
  height: 100%;
  overflow: hidden;
  @media (display-mode: browser) {
    /* Dołącz arkusz stylów dla przeglądarek */
    // @import url('styles-browser.css');
    max-height: calc(100vh - 80px);
  }
  @media (display-mode: standalone) {
    /* Dołącz arkusz stylów dla PWA */
    max-height: 100vh;
  }
  @include lg {
    display: flex;
    justify-content: center;
  }
  &__content {
    overflow: auto;
    // padding-bottom: 189px;
    display: flex;
    flex-direction: column;
    @include lg {
      height: 100%;
      width: 100%;
      max-width: 1232px;
      display: grid;
      grid-template-rows: 135px 109px 228px 240px;
      grid-template-columns: minmax(790px, 816px) 296px;
      // grid-template-columns: 816px 296px;
      justify-content: space-between;
      padding-bottom: unset;
      grid-template-areas: 
      "top top"
      "question question-count"
      "question timing"
      "question null";
    }
    @include mobile-only {
      overflow: auto;
      max-height: calc(100% - 190px);
      height: 100%;
    }
  }
  .top {
    display: flex;
    flex-wrap: wrap;
    @include lg {
      flex-wrap: unset;
      grid-area: top;
      align-items: center;
    }
    &__item {
      display: flex;
      align-items: center;
      margin: 17px;
      &:last-child {
        margin-right: 0 !important;
      }
      @include mobile-only {
        margin: 17px 5px;

      }
      // &:nth-child(n + 2) {
      //   margin: 0 17px;
      // }
      &:first-child {
        @include mobile-only {
          margin-left: 16px;
        }
      }
      .paragraph {
        margin-right: 12px;
        @include mobile-only {
          margin-right: 6px;
        }
      }
    }
    .btn {
      margin: 34px 16px  28px 16px;
      
      @include lg {
        max-width: 268px;
        margin-left: auto;
        max-height: 40px;
      }
    }
  }
  .btn {
    font-weight: 500;
  }
  .paragraph {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 126.9%;
    @include lg {
      font-size: 16px;
    }
  }
  .box {
    background-color: #426BFF;
    border-radius: 6px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    min-width: 28px;
    min-height: 28px;
    &--disabled {
      background: rgba(66, 107, 255, 0.45);
    }
    &--small {
      padding: 2px 7px;
    }
    &--time {
      padding: 2px 6px;
    }
    &--questions-count {
      padding: 8px 0;
    } 
  }

  .small {
    padding: 8.5px 0;
  }
  .questions-count {
    display: flex;
    padding: 0 16px;
    @include lg {
      grid-area: question-count;
    }
    &__item {
      width: 100%;
      &:nth-child(2) {
        margin-left: 16px;
      }
    }
    .paragraph {
      display: flex;
      flex-direction: column;
      margin-bottom: 23px;
      @include mobile-only {
        flex-direction: unset;
        span:first-child {
          margin-right: 5px;
        }
      }
    }
  }
  .timing {
    // display: none;
    width: 100%;
    height: 189px;
    position: absolute;
    bottom: 0;
    padding: 16px 16px 20px 16px;
    box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    min-width: 279px;
    .btn--disabled {
      background-color: #F9E1AC;
    }
    @include lg {
      position: relative;
      box-shadow: unset;
      grid-area: timing;
    }
    .btn {
      margin-top: 30px;
    }
    &__timer {
      position: relative;
      width: 100%;
    }
    &--answear-timer {
      position: relative;
      margin-top: 14px;
    }
    &--question-timer {
      display: flex;
      margin-top: 14px;
      .btn {
        margin-top: 0;
        max-width: 77px;
        margin-right: 12px;
      }
    }
  }
  .question {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    @include mobile-only {
      margin-bottom: 50px;
    }
    @include lg {
      grid-area: question;
      margin-top: unset;
      margin-bottom: unset;
    }
    &__question {
      padding: 0 16px;
      @include lg {
        padding: unset;
      }
    }
    &__answers {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      padding: 0 16px;
      &--basic {
        flex-direction: row;
        .question__answer-item {
          &:first-child {
            margin-right: 56px;
          }
          .box {
            width: unset;
            height: unset;
            padding: 11px 19px;
          }
        }
      }
      @include lg {
        padding: unset
      }
    }
    &__answer-item {
      display: flex;
      margin: 13px 0;
      align-items: center;
      .box {
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        cursor: pointer;
        transition: 0.3s;
        @media(min-width: 1024px) {
          &:hover {
            transition: 0.3s;
            background-color: #153ED0;
          }
        }
        &--active {
          background-color: #041C6F;
        }
      }
      .paragraph {
        margin-left: 20px;
      }
    }
    &__photo {
      width: 100%;
      height: 100%;
      margin-bottom: 28px;
      background: #E7E7E7;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 150px;
      @include lg {
        min-height: unset;
        margin-bottom: 24px;
        max-height: 465px;
      }
      video {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        @include mobile-only {
          border-radius: unset;
        }
      }
    }
    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include lg {
        border-radius: 8px;
      }
    }
  }
}


.progressBar {
  &__wrapper {
    width: 100%;
  }
  &__container { 
    background-color: #426BFF;
    border-radius: 6px;
    div {
      background-color: #a8baff!important;
      height: 46px !important;
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
      @include lg {
        height: 48px !important;
      }
    }
    .empty {
      border-radius: 4px !important;
    }
    &--reading {
      height: 45px;
      div {
        height: 45px !important;
      }
      @include lg {
        height: unset;
      }
    }
  }
  &__label {
    &--hidden {
      display: none;
    }
  }
}

.timer--counter {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 12px;
  color: #fff;
  @include lg {
    top: 14px;
  }
  &--reading {
    top: 9px;
    
  }
}