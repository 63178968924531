.dashboard {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: #fafaff;
  @include lg {
    padding-top: 56px;
  }
  &__header {
    width: 100%;
    z-index: 2;
    height: 134px;
    margin-bottom: -14px;
    background: linear-gradient(14.59deg, #03041a 0%, #222450 100%);
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    .dashboard__container {
      height: 100%;
    }
  }
  &__header-animated-element {
    filter: blur(60px);
    transform: rotate(17.45deg);
    position: absolute;
    width: 300.5px;
    height: 300.5px;
    display: flex;
    flex: 1;
    animation: animatied-first 25s infinite;
    animation-delay: 2s;
    transform: rotate(170deg);
    &--first {
      animation: animatied-first 15s infinite;
      transform: rotate(99deg);
      animation-timing-function: ease-in-out;
    }
    &--second {
      animation: animatied-second 25s infinite;
      transform: rotate(222deg);
    }
    &--third {
      animation: animated-second 5s infinite alternate; 
      transform: rotate(90deg); 
      animation-timing-function: ease-in-out;
      width: 400px;
      height: 400px;
    }
    
    
    @include mobile-only {
      display: none;
    }
  }
  &__content {
    position: relative;
    background: #fafaff;
    border-radius: 8px 8px 0px 0px;
   
  }
  &__goBack {
    position: absolute;
    top: -4px;
    left: -50px;
    cursor: pointer;
    @include mobile-only {
      left: 10px;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 126.9%;
    color: #fbfbff;
    margin-top: 32px;
    // padding: 0 16px;
    &--onclick {
      cursor: pointer;
    }
    &--exam {
      margin-top: 45px;
    }
    &--database {
      display: flex;
      align-items: center;
      @include mobile-only {
        margin-left: 50px;
      }
      .svg {
        margin-left: 10px;
      }
    }
    @include lg {
      margin-left: unset;
    }
    span {
      font-weight: 400;
    }
  }
  &__container {
    margin: 0 auto;
    width: 100%;
    position: relative;
    @include lg {
      max-width: 1240px;
    }
  }
  &__categories {
    display: flex;
    position: absolute;
    bottom: 42px;
    z-index: 2;
    @include mobile-only {
      width: 100%;
      margin-left: -16px;
      margin-bottom: -10px;
    }
 
  }
  &__category {
    background: #efefff;
    border-radius: 10px 10px 0px 0px;
    font-weight: 500;
    font-size: 16px;
    line-height: 126.9%;
    color: #8d8bb9;
    transition: 0.3s;
    height: 40px;
    padding: 0 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    @include mobile-only {
      width: 100%;
      text-align: center;
      justify-content: center;
      padding: 0 10px;
    }
    &--active {
      cursor: default;
      background: #fbfbff;
      box-shadow: 4px -4px 20px rgba(0, 0, 0, 0.08);
      color: #20203f;
      text-shadow: 
      -0.1px 0 #20203f,  // lewa strona tekstu
      0.1px 0 #20203f;  // prawa strona tekstu
    }
    
  }
}

.dashboard-top {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  &__right-side {
    width: 100%;
    margin-top: 56px;
    @include lg {
      margin-left: 80px;
    }
  }
  &__header {
    height: 56px;
    width: 100%;
    top: -1px;
    left: 0;
    z-index: 3;
    display: flex;
    position: relative;
    position: absolute;
    top: -1px;
    padding-right: 120px;
    background-color: #fff;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &__header-spacer {
    width: 80px;
    display: none;
    @include lg {
      display: flex;
    }
  }
  &__avatar {
    width: 40px;
    height: 40px;
    margin-right: 24px;
    border-radius: 100%;
    object-fit: cover;
    &.svg {
      width: 40px;
      height: 40px;
    }
  }
  &__header-content {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 20px;
    margin: 0 auto;
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    color: gray;
    justify-content: space-between;
    .svg {
      cursor: pointer;
    }
    @include lg {
      justify-content: flex-end;
    }
  }
  &__header-logo {
    width: 103px;
    height: 22px;
    .svg {
      width: 100%;
      height: 100%;
    }
  }
  &__chevron {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // position: relative;
    .svg {
      height: 10px;
      width: 10px;
      transition: 0.3s;
      transform: rotate(270deg);
    }
    &--open {
      .svg {
        transform: rotate(90deg);
      }
    }
  }
  &__menu {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #111227;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 126.9%;
  }
  &__dropdown-menu {
    transition: 0.3s;
    position: absolute;
    opacity: 0;
    pointer-events: none;
    top: 45px;
    width: 200px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
    border-radius: 6px;
    right: 80px;
    z-index: 0;
    margin-top: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 126.9%;
    &--open {
      opacity: 1;
      pointer-events: all;
      z-index: 3;
    }
    p {
      transition: 0.3s;
      font-style: normal;
      font-weight: 500;
      padding: 11.5px 16px;
      &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      &:hover {
        background-color: $purple300;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
@keyframes animatied-first {
  0% {
    left: 20%;
    bottom: 0%;
  }
  25% {
    left: 30%;
    bottom: -15%;
  }
  50% {
    left: 80%;
    bottom: -30%;
  }
  75% {
    left: 30%;
    bottom: -15%;
  }
  100% {
    left: 20%;
    bottom: 0%;
  }
}
@keyframes animated-second {
  from {
    transform: translateX(-200px); 
  }
  to {
    transform: translateX(300px); 
  }
}