@import './components/CompletedLessons.scss';
@import './components/CallToAction.scss';
@import './components/LessonTile.scss';

.departments-page {
  &__content {
    z-index: 99;
    // height: 100%;
  }
  &__title {
    margin-top: 0;
    position: relative;
    color: $purple300;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 112%;
    letter-spacing: -0.2px; 
    @include mobile-only {
      padding-left: 50px !important;
    }
  }
  &__header-container {
    align-items: center;
    display: flex;
    padding: 0 70px;
    @media(max-width: 1405px) {
      margin-left: 55px;
      margin-right: 20px;
      max-width: 100%;
    }
    @include mobile-only {
      margin-left: 0;
      padding: 0 16px 0 0px;
    }
  }
  &__container {
    margin-top: 50px;
    padding: 0 70px;
    @include mobile-only {
      padding: 25px 16px 0 16px;
      margin-top: 0;
    }
  }
  &__top {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    @include mobile-only {
      flex-wrap: wrap;
    }
    .skeleton {
      width: 448px;
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    @include mobile-only {
      flex-wrap: wrap;
    }
    &--left, &--right {
      max-width: 504px;
      width: 100%;
      @include mobile-only {
        max-width: 100%;
      }
    }
    &--left {
      .lesson-tile:last-child {
        margin-bottom: 0;
      }
    }
  }
}