.single-user-mobile-expand {
  background-color: #f0efff;
  position: absolute;
  max-height: 0;
  width: calc(100% - 32px);
  overflow: hidden;
  transition: 0.1s;
  // top: 0;
  left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // margin-top: 50px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  opacity: 0;

  &--active {
    // margin-top: 50px;
    transition: 0.1s;
    max-height: 100px;
    overflow: auto;
    height: 100%;
    opacity: 1;
  }
  &__buttons {
    display: flex;
    padding: 0 20px;
    gap: 16px;
    .btn {
      height: 40px;
      padding: 0;
    }
    .svg path {
      fill: #6d68fd;
    }
    .svg--stats path {
      stroke: #6d68fd !important;
    }
  }
  &__description {
    display: flex;
    justify-content: center;
    color: $navy400;
    font-family: Jost;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 126.9%; /* 15.228px */ 
  }
}