.delete-department {
  &__descript {
    color: $navy600;
    width: 100%;
    text-align: left;
    margin: 5px 0;
    &--bold {
      font-weight: 600;
    }
  }
  &__actions {
    margin-top: 40px;
    display: flex;
    width: 100%;
    column-gap: 20px;
    @include mobile-only {
      margin-top: auto;
      max-width: auto;
      width: 100%;
    }
    .btn {
      max-height: 48px;
      padding: 10px 0;
    }
  }
}