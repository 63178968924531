@import './components/BasicQuestionButtons.scss';
@import './components/Header.scss';
@import './components/AnswerNotification.scss';
@import './components/Question.scss';
@import './components/LessonExplanation.scss';
@import './components/SpecializedQuestionButtons.scss';
@import './components/DailyLimit.scss';
@import './components/LessonResult.scss';

.lesson-page {
  // max-height: 100vh;
  // overflow: hidden;
  height: calc(100% - 120px);
  &__header--container {
    padding: 0 70px;
    @include mobile-only {
      padding: 0 16px;
    }
  }
  &__container {
    padding: 50px 70px 70px 70px;
    display: flex;
    justify-content: space-between;
    overflow: auto;
    overflow-x: hidden;
    height: 100%;
    @include mobile-only {
      padding: 0 0 0 0;
      height: unset;
    }
    &--summary {
      height: unset;
    }
  }
  &__header {
    &--close {
      display: flex;
      align-items: center;
      svg {
        margin-left: 4px;
      }
    }
    &--title {
      padding-left: 0;
    }
  }
  &__left {
    max-width: 712px;
    width: 100%;
    // overflow: hidden;
    @include mobile-only {
     max-width: 100%;
     padding-bottom: 100px;
    }
  }
  &__right {
    max-width: 416px;
    width: 100%;
    margin-right: auto;
    margin-left: 120px;
    z-index: 9;
    @include mobile-only {
      display: none;
    }
  }
  &__answer-buttons {
    display: flex;
    flex-direction: column;
    max-width: 401px;
    @include mobile-only {
      max-width: 100%;
      padding: 0 16px;
    }
    // .btn {
    //   margin-top: 20px;
    // }
    &--specialized {
      max-width: 100%;
    }

    .call-to-action {
      margin: 20px 0;
    }

  }

  &__button {
    &--hide {
      padding: 0;
      opacity: 0;
      max-height: 0;
      pointer-events: none;
    }
    &--show {
      padding: 14px;
      opacity: 1;
      max-height: 100;
      pointer-events: all;
    }
    &--save-task {
      margin-top: 15px;
    }
  }
}



@keyframes slideOutToLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Dodaj klasę do ukrycia pytania */
.lesson-page__question--hide {
  animation: slideOutToLeft 0.5s ease both;
}

/* Dodaj klasę do pokazania kolejnego pytania */
.lesson-page__question--show {
  animation: slideInFromRight 0.5s ease both;
}
