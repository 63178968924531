.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% + 40px), -50%);
  width: calc(100% - 40px);
  height: fit-content;
  max-width: 608px;
  border-radius: 8px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $white;
  box-shadow: 0px 4px 20px rgba(17, 19, 56, 0.08);
  padding: 36px 17px;

  @include lg {
    padding: 40px 94px;
  }

  &__backdrop {
    position: fixed; /* Ustawienie na fixed, aby backdrop również pozostał na ekranie */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    background: rgba(34, 36, 80, 0.2);
    backdrop-filter: blur(1px);
    pointer-events: none;

    &--open {
      opacity: 1;
      pointer-events: all;
    }
  }

  &--full {
    height: 100%;
    width: 100vw;
    min-height: 100vh;
    max-width: 100vw;
    top: 0;
    left: 0;
    transform: unset;
    padding-bottom: 50px;
    overflow: auto;
  }

  &__short {
    height: calc(100vh - 30%);
    width: 100%;
    top: unset;
    transform: translate(-50%);
    bottom: -800px;
    transition: 0.4s;
    &--open {
      transition: 0.4s;
      bottom: 0;
    }
  }
}


.modal-mobile--full {
  @include mobile-only {
    top: 0;
    transform: translate(-50%, 0);
    width: 100%;
    height: 100%;
    max-height: 100%;
    // height: calc(100vh - 90px);
    // max-height: calc(100vh - 90px);
    justify-content: flex-start;
    position: fixed;
    overflow: auto;
    border-radius: 0;
    padding-top: 46px;
    &:before {
      position: absolute;
      content: '';
      top: -3px;
      left: 0px;
      height: 37px;
      width: 100vw;
      background-color: #222450;
    }
    &:after {
      position: absolute;
      content: '';
      top: 28px;
      left: 0;
      height: 15px;
      width: 100%;
      background-color: #fff;
      border-radius: 8px 8px 0 0;
    }

  }
}