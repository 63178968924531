@import './components/call-to-action.scss';

.exam-page {
  height: calc(100vh - 72px);
  @include mobile-only {
    height: auto !important;
  }
  .dashboard__container {
    padding: 0 70px;
    @include mobile-only {
      padding: 0 16px;
    }
    .max-retries {
      max-width: 100%;
    }
  }
  .exam-page {
    padding-top: 28px;
    height: calc(100vh - 176px);
    padding-bottom: 50px;
    @include mobile-only {
      height: unset;
      max-width: 100vw;
      padding:  16px;
    }
    &__container {
      max-width: 607px;
      padding: 0;
    }
  }
  &__descript {
    font-weight: 800;
    font-size: 18px;
    line-height: 126.9%;
    color: #000000;
  }
  &__box-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    height: 100%;
    position: relative;
  }
  &__box {
    background: $purple300;
    border-radius: 6px;
    font-weight: 800;
    font-size: 48px;
    line-height: 126.9%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 193px;
    position: relative;
    color: #393C73;
    min-height: 193px;
    &--skeleton {
      padding: 0;
      background: unset;
      width: 100%;
      span {
        width: 100%;
      }
    }
    &--img {
      position: absolute;
      bottom: -20px;
      left: 40px;
      font-size: 60px;
      @include mobile-only {
        left: 10px;
      }
    }
    p:nth-child(2) {
      font-size: 40px; 
    }
  }
  &__box-title {
    max-width: 134px;
    text-align: center;
    color: $navy400;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; 
    margin-top: 18px;
    margin-bottom: 28px;
  }
  &__chances-title {
    margin-bottom: 32px;
    color: $navy400;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  &__chances {
    .CircularProgressbar {
      width: 88px;
      min-width: 88px;
    }
    .CircularProgressbar-text {
      font-weight: 700;
      font-size: 22px !important;
      line-height: 22px;
      /* or 69% */

      text-align: center;
      letter-spacing: -0.02em;
      transform: translateX(-20px) translateY(7px);
    }
    &--skeleton {
      min-height: 607px;
      
    }
  }
  &__chances-text {
    color: #6b6b6b;
    max-width: 384px;
    text-align: center;
    color: var(--granatowy-400, #393C73);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */ 
    margin-top: 32px;
  }
  &__chances-wrapper {
    background: #efeeff;
    border-radius: 10px;
    padding: 26px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 37px;
    @include mobile-only {
      padding: 26px 16px;
    }
    &--skeleton {
      max-height: 400px;
      padding: 0;
      background: none;
      span {
        width: 100%;
        height: 100%;
        max-height: 400px;
      }
    }
  }
  &__lesson-list-heading {
    margin-top: 57px;
    color: $navy400;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 19.2px */ 
    text-align: center;
    margin-bottom: 20px;
  }
  &__lesson-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }
  &__lesson-item {
    background-color: rgba(212, 211, 243, 0.5);
    margin: 5px 0;
    padding: 12px 30px 12px 20px;
    border-radius: 5px;
    position: relative;
    &__onclick {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    &__heading {
      color: $navy400;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 112%; /* 17.92px */
      letter-spacing: -0.16px; 
    }
    &__counter {
      color: $navy300;
      font-family: Jost;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 112%; /* 15.68px */
      letter-spacing: -0.14px; 
      margin-top: 6px;
    }
    &__chevrone {
      position: absolute;
      right: 0;
      top: 10px;
      transform: rotate(180deg);
      cursor: pointer;
      svg path{
        stroke: $purple500;
      }
    }
  }
  &__button {
    margin-top: 18px;
  }
  &__available-test {
    width: 100%;
    background-color: #CAC8FF;
    height: 193px;
    margin-top: 37px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px 20px 20px;
    &--skeleton {
      padding: 0;
      background-color: unset;
    }
    &--disable {
      background-color: rgba(255, 225, 225, 1);
      max-height: 143px;
    }
    &__content {
      max-width: 224px;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;
      &--skeleton {
        padding: 0;
        max-width: 100%;
        width: 100%;
        span {
          width: 100%;
        }
      }
    }
    &__title {
      color: $navy400;
      text-align: center;
      font-family: Jost;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 19.2px */ 
    }
    &__numbers {
      color: $navy400;
      text-align: center;
      font-family: Jost;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      margin-top: 18px; /* 48px */ 
      &--disable {
        color: rgba(255, 108, 108, 1);
      }
    }
    .btn {
      max-height: 40px;
    }
    &__premium {
      display: flex;
      align-items: center;
      color: $navy400;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 28.8px */ 
    }
  }
}
