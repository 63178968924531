.navigation-item {
  border-radius: 5px;
  // .svg--trophy path {
  //   fill: $purple300;
  // }
  &:hover {
    background-color: $navy500;
    transition: 0.3s;
  }
}
.navigation {
  height: 68px;
  width: 100%;
  background-color: #111338;
  justify-content: center;
  position: sticky;
  bottom: 0;
  z-index: 2;
  padding: 10px 16px;
  transition: 0.3s;
  display: flex;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  @include lg {
    width: 72px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    left: 0;
    z-index: 999;
    border-radius: unset;
  }
  .navigation__logo {
    width: 100%;
    &:hover {
      background-color: unset;
    }
    .svg {
      width: 156px;
    }
  }
  &--open {
    width: 230px;
    align-items: flex-start;
    transition: 0.3s;
    .navigation-item {
      justify-content: flex-start;
      padding-right: 20px;
    }
  }
  &-item {
    display: flex;
    position: relative;
    overflow: hidden;
    justify-content: center;
    text-decoration: none;
    white-space: nowrap;
    margin-right: 40px;
    width: 40px;
    height: 40px;
    &:last-child {
      margin-right: 0;
    }
    @include lg {
      height: unset;
      margin-right: 0;
      margin-bottom: 16px;
      width: 100%;
      display: flex;
      padding: 8px;
      justify-content: unset;
    }
    &:active {
      text-decoration: none;
    }
    &.active {
      background-color: $navy400;
      border-radius: 5px;
    }
    .svg {
      flex-shrink: 0;
    }
    &__name {
      text-decoration: none;
      color: #e1e0ff;
      margin-left: 20px;
      transition: 0.3s;
      font-weight: 500;
      font-size: 14px;
      &--darkest {
        color: #b0adfe;
      }
    }
    &--soon {
      &::after {
        content: 'WKRÓTCE';
        color: #ffffff;
        font-weight: 800;
        font-size: 6px;
        line-height: 7px;
        position: absolute;
        top: -2px;
        background: #5b5b5b;
        width: 36px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &__logo {
    cursor: pointer;
    margin-bottom: 44px;
  }
}
