@import './_reset';
@import './_variables';
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400;500;600;700;800;900&display=swap');
html {
  scroll-behavior: smooth;
  height: stretch;
  height: -webkit-fill-available;
}
.scrollable-container {
  scroll-behavior: smooth;
}
body {
  line-height: 1.5;
  font-size: 16px;
  font-family: $primaryFont;
  min-height: 100vh;
  min-height: stretch;
  min-height: -webkit-fill-available;
  display: flex;
}
.page-wrapper {
  height: 100%;
  background-color: #fafaff;
  position: relative;
  border-radius: 8px 8px 0 0;
  margin-top: -10px;
  z-index: 99;
  &--save-task {
    margin-top: 0;
  }
}
.modal-mobile {
  max-height: 100vh;
  overflow: hidden;
  .navigation {
    display: none;
  }
  .dashboard {
    overflow: hidden;
  }
}
#root .slide-routes {
  @include lg {
    max-width: 100%;
    width: calc(100vw - 72px);
    overflow-y: auto !important;
    overflow-x: hidden;
  }
  @include mobile-only {
  height: 100%;
  }
}
#root {
  width: 100%;
}
.item {
  width: 100%;
  @include lg {
    display: flex;
  }
}
html,
body {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3, mirror=1);
  // -webkit-transform: scale(1, 1);
  // -moz-transform: scale(1, 1);
  // -ms-transform: scale(1, 1);
  // -o-transform: scale(1, 1);
  // transform: scale(1, 1);
}
.Toastify__toast-icon .svg {
  height: 24px;
  min-width: 24px;
}
.Toastify__toast {
  @include mobile-only {
    width: calc(100% - 32px);
    margin-left: 16px;
    border-radius: 8px !important;
  }
}
.Toastify__toast-container {
  @include mobile-only {
    bottom: 80px !important;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  -ms-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
}


.loader {
    width: 18px;
    height: 18px;
    border: 2px solid #e0e0e0;
    border-bottom-color: #616161;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  } 