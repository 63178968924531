.lesson-page__notification {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 126.9%;
  letter-spacing: -0.14px; 
  height: 0px;
  overflow: hidden;
  transition: 0.3s;
  padding:  0 15px;
  margin-bottom: 10px;
  &--active {
    transition: 0.3s;
    padding: 15px 20px;
    height: 50px;
  }
  .svg {
    width: 20px;
    height: 20px;
  }
  &--corectly {
    color: rgba(0, 154, 98, 1);
    background-color: rgba(216, 246, 235, 1)
  }
  &--wrong {
    color: rgba(255, 108, 108, 1);
    background-color: rgba(255, 225, 225, 1);
  }
}