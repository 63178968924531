@import './components/EditQuestionModal.scss';
@import './components/CurrentDateForm.scss';


.question-database {
  .dashboard__container {
    padding: 0 70px;
    @include mobile-only {
      padding: 0 16px;
      margin-top: 10px;
    }
  }
  .dashboard__categories {
    @include mobile-only {
      margin-left: -16px;
    }
  }
}
.questions-list {
  width: 100%;
  z-index: 2;
  padding-bottom: 40px;
  background: #fafaff;
  border-radius: 8px 8px 0px 0px;
  flex: 1;
  // padding-top: 44px;
  padding: 16px 16px 0 16px;
  @include mobile-only {
    border-radius: 0;
    padding: 44px 0 0 0;
  }
  &--single {
    @include mobile-only {
      margin-top: -35px;
      border-radius: 8px 8px 0px 0px;
      padding: 20px 16px 0 16px;
    }
  }
  &--pagination {
    margin: 32px 0;
  }
  &--redirector {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: calc(100% - 100px);
    cursor: pointer;
    @include mobile-only { 
      height: 50px;
      top: unset;
    }
  }
  &__thead {
    color: black;
    height: 32px;
    @include mobile-only {
      display: none;
    }
  }
  &__pagination {
    margin-top: 32px;
  }
  &__table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 4px;
  }
  &__td {
    text-align: left;
    vertical-align: middle;
    border-collapse: collapse;
    height: 56px;
    font-weight: 400;
    font-size: 14px;
    line-height: 134%;
    color: #393c73;
    background-color: #f0efff;

    &--breake {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      width: 100% !important;
    }
    &--suspended {
      color: #808080;
    }
    &--inactive {
      color: #808080;
    }
    &--name {
      display: flex;
      align-items: center;
      gap: 12px;
      .svg {
        min-width: 32px;
      }
    }
    &:first-child {
      padding-left: 25px;
      width: 73px;
      border: solid transparent;
      border-style: none solid solid none;
      border-radius: 8px 0 0 8px;
      @include lg {
        width: 10%;
        padding-left: 37px;
      }
    }
    &:nth-child(2) {
      width: 12%;
      @include mobile-only {
        display: none;
      }
    }    
    &:nth-child(3) {
      width: 20%;
      @include mobile-only {
        display: none;
      }
    }  
    &:nth-child(4) {
      width: 25%;
      @include mobile-only {
        width: 100%;
        padding-left: 30px;
      }
    }
    &:nth-child(5) {
      width: 25%;
      @include mobile-only {
        display: none;
      }
    }
    &:last-child {
      padding-right: 14px;
      text-align: right;
      border: solid transparent;
      border-style: none solid solid none;
      border-radius: 0 8px 8px 0;
      @include lg {
        padding-right: 27px;
      }
      @include mobile-only {
        max-width: 40px;
      }
    }
  }
  &__tr {
    margin-bottom: 8px;
    transition: 0.3s;
    position: relative;
    &--inactive {
      .users-list__td {
        color: $navy200;
      }
    }
  }
  th {
    font-weight: 400;
    color: $navy400;
    font-size: 14px;
    line-height: 126.9%;
    text-align: start;
    vertical-align: middle;
    height: 40px;
    &:first-child {
      padding-left: 11px;
      @include lg {
        padding-left: 37px;
      }
    }
    &:last-child {
      width: 100px;
    }
  }
  &__menu {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    .svg {
      cursor: pointer;
    }
  }

  &__data-bar {
    display: flex;
    justify-content: flex-end;
  }
}