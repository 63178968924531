.react-loading-skeleton {
  --base-color: #E1E0FF !important;
  --highlight-color: #cfcef7 !important;
  // --animation-duration: 1.5s;
  // --animation-direction: normal;
  // --pseudo-element-display: block;
  background-color: var(--base-color);
  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;
  position: relative;
  user-select: none;
  overflow: hidden;
  z-index: 1;
}