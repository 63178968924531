.router {
  height: 100%;
  @include lg {
    min-height: 100vh;
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: row-reverse;
  }
  @include lg {
    max-height: 100vh;
    overflow: hidden;
  }
}
html {
  overflow-x: hidden;
}
// #root .router--withoutMenu {
//   .slide-routes .item{
//     max-height: 100vh;
//   }
// }