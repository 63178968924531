@import './components/CategoryBox.scss';
@import './components/Departments.scss';
@import './components/Statistic.scss';
@import './components/SingleTask.scss';
@import './components/SaveTasks.scss';
@import './components/SaveTasksEmptyState.scss';

.learning-page {
  z-index: 2;
  padding-bottom: 0;
  // overflow: auto;
  height: 100%;
  @include mobile-only {
    border-radius: unset;
    border-radius: 8px;
  }
  &--statistics {
    height: 100%
  }
  .learning-page {
    padding: 0;
  }
  &__categories {
    display: flex;
    margin-bottom: 24px;
  }
  // &__categories-item {
  //   margin-top: -10px !important;
  // }
  .lesson-page__container {
    padding-top: 40px !important;
  }
  @include mobile-only {
    // padding-top: 10px;
    height: unset;
    padding: 10px 16px 10px 16px;
    &__categories {
      max-width: 100%;
      width: 100%;
    }
    &__categories-item {
      max-width: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 14px;
      @include mobile-only {
        width: calc(100% + 10px);
      }
      &:last-child {
        flex-shrink: 0.9;
      }
    }
  }
  &__category {
    margin-right: 42px;
    font-weight: 800;
    font-size: 14px;
    line-height: 126.9%;
    color: #000000;
    &:last-child {
      margin-right: 0;
    }
  }
  &__container {
    height: 100%;
    padding: 30px 70px;
    @include mobile-only { 
      height: unset;
      padding: 30px 0;
    }
  }
  &__header {
    .dashboard__categories {
      margin-bottom: 0px;
    }
    .dashboard__container {
      padding: 0 70px;
      @media(max-width: 1407px) {
        padding: 0 50px;
      }
      @include mobile-only {
        padding: 0;
      }
      &--learning {
         padding: 0 70px;
         @include mobile-only {
          padding: 0;
        }
      }
    }
    .dashboard__title {
      @include mobile-only {
        padding: 0 16px;
      }
    }
  }
  &--dashboard-container {
    display: flex;
    @include mobile-only {
      // margin-top: 15px;
      flex-direction: column;
      padding: 0 16px;
      margin-top: 10px;
    }
  }
}

.learning-page__categories-user {
  margin-left: 0;
  margin-bottom: 3px !important;
  @include mobile-only {
    margin-bottom: -2px !important;
  }
}