@import './components/AnswersModal.scss';

.drivingTestSummaryPage {
  display: flex;
  width: 100%;
  min-height: 100vh;
  padding-top: 61px;
  overflow: auto;
  background-color: #FAFAFF;
  &--disable {
    display: none;
  }
  .exit {
    display: flex;
    position: absolute;
    display: flex;
    align-items: center;
    cursor: pointer;
    right: 10%;
    top: 21px;
    right: 15px;
    @include lg {
      right: 94px;
      top: 64px;
    }
    .svg {
      margin-left: 5px;
    }
  }
  .content {
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: 0 16px;
    @include lg {
      padding-bottom: 0;
      margin-left: -72px;
      width: 100vw;
      flex-direction: unset;
      justify-content: center;
      align-items: flex-start;
    }
  }
  .container {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 30px;
    @include lg {
      flex-direction: unset;
      position: relative;
    }
  }

  .stats {
    max-width: 406px;
    justify-self: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__icon {
      font-size: 64px;
    }

    &__result {
      margin-top: 8px;
      color: #03041A;
      text-align: right;
      font-family: Jost;
      line-height: 126.9%; 
      font-weight: 500;
      display: flex;
      font-size: 20px;
      .paragraph {
        font-weight: 600;
        font-size: 20px;
        margin-left: 5px;
        &--green {
          color: #00b473;
        }
        &--red {
          color: #ff6c6c;
        }
      }
    }
    &__score {
      margin-top: 56px;
      width: 100%;
    }
    &__score-item {
      color: $navy700;
      font-size: 18px;
      font-family: Jost;
      font-weight: 500;
      line-height: 126.9%; 
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      span {
        font-weight: 700;
      }
    }
    .btn {
      display: none;
      @include lg{
        margin-top: 48px;
        display: flex;
      }
    }
  }

  .btn--mobile {
    max-width: 406px;
    margin-top: 50px;
    @include lg {
      display: none;
    }
  }
  


  // .questions {
  //   &__content {
  //     margin-top: 48px;
  //     max-width: 406px;
  //     width: 100%;
  //     display: flex;
  //     flex-wrap: wrap;
  //     gap: 12px;
  //   }
  //   &__item {
  //     height: 35px;
  //     width: 35px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     color: #FFF;
  //     font-size: 18px;
  //     font-weight: 700;
  //     line-height: 126.9%;
  //     border-radius: 4px;
  //     @media(min-width: 440px) {
  //       width: 40px;
  //       height: 40px;
  //     }
  //     &--green {
  //       background-color: #00b473;
  //     }
  //     &--red {
  //       background-color: #ff6c6c;
  //     }
  //     &--active {
  //       cursor: pointer;
  //     }
  //   }
  // }

  .questions {
    &__content {
      margin-top: 48px;
      max-width: 406px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
    }
    &__item {
      flex: 1 0 calc(12% - 12px); /* Lub dostosuj wartość, aby zmieniać ilość elementów w wierszu */
      min-height: 0;
      max-height: 40px;
      min-width: 0;
      max-width: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFF;
      font-size: 18px;
      font-weight: 700;
      line-height: 126.9%;
      border-radius: 4px;
      padding: 10px;
      
      &--green {
        background-color: #00b473;
      }
      &--red {
        background-color: #ff6c6c;
      }
      &--active {
        cursor: pointer;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    
    .btn:first-child {
      margin-right: 12px;
    }
    &--mobile {
      margin-top: 50px;
      flex-direction: column;
      max-width: 406px;
      @include lg {
        display: none;
      }
      .btn:first-child {
        margin-bottom: 12px;
      }
    }
  }
  &__max-retries-btn {
    display: flex;
    flex-direction: column;
  }
}

.max-retries {
  font-size: 16px;
  line-height: 126.9%;
  letter-spacing: 0.16px; 
  color: $navy700;
  padding: 16px 20px;
  background-color: #fee2cd;
  max-width: 406px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  display: none;
  .svg {
    margin-left: 15px;
  }
  @include lg {
    display: flex;
    margin-top: 40px;
  }
  &--mobile {
    margin-top: 32px;
    display: flex;
    @include lg {
      display: none;
    }
  }
}



.sidebar-invitation {
  padding: 31px 25px 34px 28px;
  background-color: #FBFBFF;
  box-shadow: 0px 4px 20px 0px rgba(34, 36, 80, 0.08);
  position: relative;
  max-width: 406px;
  width: 100%;
  margin-top: 72px;
  border-radius: 6px;
  @include lg {
    max-width: 300px;
    margin: 70px 120px 0 20px;
    position: absolute;
    left: -450px;
  }
  @media(max-width: 1400px) {
    left: -400px;
  }
  @media(max-width: 1240px) {
    left: -370px;
  }
  @media(max-width: 1170px) {
    left: -330px;
  }
  @media(max-width: 1024px) {
    left: unset;
  }

  &__paragraph {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 126.9%;
    color: $navy500;
  }
  &__call-to-action {
    color: $purple500;
    font-weight: 700;
    font-size: 16px;
    line-height: 126.9%;
    margin-top: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .svg {
      margin-left: 5px;
    }
  }
  &__icon {
    position: absolute;
    height: 40px;
    width: 40px;
    top: -15px;
    right: 0;
    @include lg {
      right: -15px;
    }
    &--star {
      height: 50px;
      width: 50px;
      top: -30px;
      @include lg {
        height: 74px;
        width: 74px;
        right: -30px;
      }
    }
  }
  &--answersModal {
    position: relative;
    left: unset;

  }
}
.answers-list {
  padding: 0 20px;
  position: relative;
  @media(max-width: 1500px) {
    display: flex;
  }
  @include mobile-only {
    flex-direction: column-reverse;
    padding-bottom: 100px;
  }
  .sidebar-invitation {
    height: 200px;
    position: absolute;
    left: -350px;
    @media(max-width: 1500px) {
      left: unset;
      position: relative;
    }
    @include mobile-only {
      max-width: 800px;
      height: auto;
      width: 100%;
    }
  }
}