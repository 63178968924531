.departments {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 48px;
  margin-bottom: 15px;
  @include mobile-only {
    padding: 0;
    margin-top: -4px;
    flex-direction: column;
  }
}