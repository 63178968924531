.lesson-result {
  display: flex;
  justify-content: center;
  width: 100%;
  @include mobile-only {
    padding: 20px 16px;
  }
  &__top {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__description {
    color: $navy700;
    text-align: center;
    font-family: Jost;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 126.9%; 
    max-width: 424px;
    text-align: center;
    margin: 10px auto 0 auto;
  }
  &__score {
    color: $navy300;
    text-align: center;
    font-family: Jost;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 126.9%; 
    margin: 40px 0;/* 22.842px */ 
  }
  &__button {
    display: flex;
    justify-content: center;
    .btn {
      max-width: 295px;
      max-height: 48px;
    }
  }
  &__paragraph {
    color: $navy700;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 126.9%; /* 20.304px */ 
    margin-top: 60px;
    margin-bottom: 16px;
  }
}