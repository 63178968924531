.gamification-page {
  &__header {
    display: flex;
    align-items: center;
    @include mobile-only {
      padding: 0;
    }
    .dashboard__container {
      padding: 0 70px;
      @include mobile-only {
        padding: 0 16px;
      }
    }
  }
  &__content {
    z-index: 9;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  &__empty {
    max-width: 343px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      color: $navy700;
      text-align: center;
      font-family: Jost;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 126.9%; /* 25.38px */ 
      margin-bottom: 30px;
      margin-top: 128px;
      @include mobile-only {
        margin-top: 40px;
      }
    }
    span {
      color: $navy400;
      text-align: center;
      font-family: Jost;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */ 
      margin-bottom: 72px;
    }
    div {
      font-size: 48px;
    }
  }
}