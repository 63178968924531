.specialized-question-buttons {
  display: flex;
  flex-direction: column;
  .item {
    display: flex;
    width: 100%;
    margin-bottom: 30px;
    .box {
      width: 48px;
      height: 48px;
      background-color: $purple300;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      margin-right: 18px;
      flex-shrink: 0;
      color: $navy600;
      font-size: 20px;
      font-weight: 600;
      cursor: pointer;
      transition: 0.3s;
      position: relative;
      &--active {
        transition: 0.3s;
        color: $purple200;
        background-color: $navy600;
      }
      &--disabled {
        cursor: unset;
        pointer-events: none;
      }
      &--correct {
        background-color: rgba(34, 220, 153, 1);
        color: $navy600;
      }
      &--wrong {
        background-color: rgba(255, 108, 108, 1);
        color: $navy600;
      }
    }
    .description {
      margin-top: 10px;
      color: $navy600;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 126.9%; 
    }
    .checked {
      position: absolute;
      width: 32px;
      height: 32px;
      top: -15px;
      right: -15px;
    }
  }
}