.remove-lesson {
  &__descript {
    width: 100%;
    text-align: left;
    color: $navy600;
  }
  &__bold {
    font-weight: 500;;
  }
  &__buttons {
    max-width: 400px;
    width: 400px;
    display: flex;
    margin-top: 40px;
    @include mobile-only {
      margin-top: auto;
      max-width: auto;
      width: 100%;
    }
    .btn {
      max-height: 48px;
      &:first-child {
        margin-right: 16px;
      }
    }
  }
}