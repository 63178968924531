.call-to-action {
  background-color: $purple300;
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  width: 100%;
  align-items: center;
  max-width: 503px;
  @include mobile-only {
    max-width: 100%;
  }
  &__icon {
    width: 40px;
    height: 40px;
  }
  &--text {
    display: inline;
    margin-right: 5px;
  }
  &--right {
    margin-left: 17px;
    color: #393C73;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.14px; 
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @media(max-width: 505px) {
      display: block;
    }
  }
  &--standard {
    flex-direction: column;
    span {
      margin-left: 0;
    }
  }
  &--action {
    color: $purple500;
    display: flex;
    align-items: center;
    // margin-left: 5px;
    cursor: pointer;
    @include mobile-only {
      display: inline;
      margin-left: 5px;
      svg {
        margin-bottom: -7px;
      }
    }
    @media(max-width: 505px) {
      margin-left: 0px;
    }
  }
  &--action-standard {
    width: 100%;
    text-align: left;
  }
}