.lesson-question {
  &__photo-container {
    display: flex;
    max-width: 712px;
    max-height: 340px;
    height: 100%;
    width: 100%;
    position: relative;
    &--empty {
      max-height: 0;
    }
    .video {
      margin-top: -5px;
      video {
        border-radius: 8px;
        @include mobile-only {
          border-radius: 0;
        }
      }
    }
    img {
      width: 100%;
      border-radius: 8px;
    }
    @include mobile-only {
      max-height: 230px;
      max-width: 100%;
      &:after {
        content: '';
        width: 100%;
        height: 30px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        position: absolute;
        background-color: #fafaff;
        bottom: -20px;
      }
    }
  }
  &__photo {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    @include mobile-only {
      border-radius: 0;
      border-color: none;
    }
  }
  &__question {
    max-width: 639px;
    padding-right: 50px;
    margin-top: 26px;
    color: $navy600;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 126.9%; 
    margin-bottom: 40px;
    @include mobile-only {
      font-size: 18px;
      padding:0 66px 0 16px;
    }
  }
  &__save-label {
    transition:0.3s ;
    &__container {
      border-radius: 40px;
      width: 40px;
      height: 40px;
      background-color: #D3D2FF;
      position: absolute;
      right: 0;
      bottom: - 60px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      @include mobile-only {
        right: 16px;
      }
    }
    &__container--active {
      transition: 0.3s;
      background-color: $purple600;
    }
    &--active path{
      transition:0.3s ;
      fill: $purple300;
    }
  }
}
