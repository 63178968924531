.statistic {
  &__wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
    // height: 100%;
  }
  &__content {
    max-width: 607px;
    height: 100%;
    width: 100%;
  }
  &__boxes {
    display: flex;
    gap: 16px;
    width: 100%;
  }
  &__box {
    background-color: rgba(225, 224, 255, 1);
    padding: 18px 53px;
    border-radius: 6px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 295px;
    @include mobile-only {
      padding: 16px;
      min-width: unset;
    }
    &--skeleton {
      padding: 0;
      background-color: none;
      span{
        width: 100%;
      }
    }
  }
  &__box-paragraph {
    color: $navy400;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; 
    margin-bottom: 16px;
    @include mobile-only {
      font-size: 14px;
    }
  }
  &__box-counter {
    color: $navy400;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-top: auto;
    margin-bottom: 40px;
    &--unlimited {
      font-size: 24px; 
    }
  }
  &__box-icon {
    font-size: 60px;
    position: absolute;
    bottom: -40px;
    left: 40px;
    @include mobile-only {
      left: 10px;
    }
  }

  &__effectiveness {
    border-radius: 6px;
    background: #CAC8FF;
    margin-top: 34px;
    padding: 18px 0 34px 0;
    &--skeleton {
      background: none;
      padding: 0;
    }
  }
  &__effectiveness-header {
    color: $navy400;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%; 
    margin-bottom: 14px;
  }
  &__effectiveness-counter {
    color: $navy400;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 120%; 
    margin-bottom: 14px;

  }
  &__effectiveness-paragraph {
    color: $navy400;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 120%; 
  }
  &__lesson-list {
    background-color: #efeeff;
    padding: 30px 20px;
    border-radius: 6px;
    margin-top: 34px;
    .exam-page__lesson-list {
      margin-top: 0;
    }
    &--skeleton {
      padding: 0;
      background: 0;
    }
    &--heading {
      margin-top: 0 !important;
    }
  }
}