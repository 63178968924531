.transaction-success {
  &__wrapper {
    display: flex;
    width: 100vw;
    max-height: 100vh;
    height: 100%;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(45deg, #03041A 0%, #222450 100%);
    overflow: hidden;
    position: relative;
    padding: 0 20px;
    @include mobile-only {
      height: 100vh;
    }
  }
  &__content {
   display: flex;
   flex-direction: column; 
   align-items: center;
   margin-top: 150px;
   position: relative;
   svg {
     margin-bottom: 20px;
   }
   .btn {
    max-width: 246px;
    max-height: 48px;
    margin-top: 63px;
    z-index: 9999999;
   }
  }
  &__header {
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 138%;
    margin-bottom: 20px; 
    @include mobile-only {
      font-size: 20px;
    }
  }
  &__paragraph {
    color: #FFF;
    text-align: center;
    font-family: Jost;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; 
    @include mobile-only {
      font-size: 16px;
    }
  }
}