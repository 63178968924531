.completed-lessons {
  width: 100%;
  max-width: 504px;
  @include mobile-only {
    max-width: 100%;
    margin-bottom: 20px;
  }
  &__title {
    color: $navy300;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 126.9%; 
    min-height: 20.3px;
  }
  &__progress-bar {
    height: 9px;
    width: 100%;
    background-color: $purple300;
    margin-top: 12px;
    border-radius: 9px;
    &__item {
      background-color: $navy500;
      height: 9px;
      border-radius: 9px;
    }
  }
  .skeleton {
    max-width: 448px;
    width: 100%;
  }
}