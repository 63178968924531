
.react-datepicker__children-container {
  padding: 0;
}
.questions-list {
  .current-date-form {
    max-width: 260px;
    width: 100%;
    @include mobile-only {
      max-width: 100%;
    }
  }
  .react-datepicker-popper {
    @include mobile-only {
      max-width: 100% !important;
      width: 100%;
    }
  }
}
.question-date {

  .react-datepicker {
    display: flex;
    flex-direction: column;
    border: none;
    box-shadow: 0px 4px 20px 0px rgba(17, 19, 56, 0.08);
    top: -22px;
    padding: 10px;

    &__children-container {
      width: unset;
      @include mobile-only { 
        width: 100%;
        margin: 0;
        padding: 0 20px;
      }
    }
    &__header {
      background-color: #fff;
      border-bottom: 0;
      padding-top: 20px;
    }
    &__current-month {
      color: $navy600;
      text-transform: capitalize;
    }
    &__navigation {
      top: 15px;
      &:hover {
        border-color: #6D68FD;
      }
    }
    &__navigation-icon::before {
      border-color: $navy600 !important;
    }
    &__triangle {
      display: none;
    }
    &__day-name {
      color: $navy200;
      text-transform: capitalize;
      font-size: 14px;
      @include mobile-only { 
        width: calc(100% / 8);
      }
    }

    &__day{ 
      border: 2px solid transparent;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      background-color: #EEEDFF;
      @include mobile-only {
        width: calc(100% / 8);
      }
      &--outside-month {
        background-color: transparent;
      }
      &--disabled {
        color: $navy200;
        border: 2px solid transparent;
        background-color: transparent;
        &:hover {
          border: 2px solid transparent;
          color: $navy200;
        }
      }
      &:hover {
        transition: 0.3s;
        background-color: transparent;
        color: $purple500;
        border: 2px solid var(--fioletowy-500, #6D68FD);
      }
      &--keyboard-selected, &--selected {
        background-color: $purple500;
        color: #fff;
      }
    }
    &__today-button {
      border-top: 0;
      background-color: #fff;
      padding: 20px 16px 20px 16px;
    }
  }
  &__buttons {
    display: flex;
    gap: 16px;
    padding: 15px 0;
    @include mobile-only {
      max-width: 100%;
      width: 100%;
    }
    .btn {
      max-width: 120px;
      font-size: 14px;
      max-height: 48px;
      width: 50% !important;
      @include mobile-only {
        max-width: 100%;
        width: 100% !important;
      }
    }
  }
}