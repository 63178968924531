.category-box {
  background: #EBEBFF;
  border-radius: 10px;
  min-height: 154px;
  padding: 19px 16px;
  max-width: 32%;
  width: 100%;
  position: relative;
  &__onclick {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  &--skeleton {
    padding: 0;
    background: none;
  }
  &--finished-medal {
    position: absolute;
    font-size: 64px;
    top: -15px;
    right: 0;
  }
  &--finished {
    background-color: rgba(225, 224, 255, 1);
  }
  @include mobile-only {
    max-width: 100%;
  }

  &__image {
    width: 60px;
    height: 60px;
    background: #eceaea;
    object-fit: cover;
    border-radius: 5px;
  }
  &__top {
    display: grid;
    grid-template-columns: 60px 1fr 40px;
    margin-bottom: 18px;
  }
  &__title {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    h3 {
      font-size: 14px;
      color: $navy500;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 112%;
      letter-spacing: -0.14px; 
      max-width: 227px;
    }
    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 126.9%;
      color: #4a4a4a;
      margin-top: 10px;
    }
  }
  &__steps-title {
    font-weight: 500;
    font-size: 10px;
    line-height: 126.9%;
    color: #4a4a4a;
    margin-top: 10px;
  }
  &__button {
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    .svg {
      transform: rotate(180deg);
      color: $purple500;
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &--progress {
      display: flex;
      width: 100%;
      align-items: center;
    }
  }
  &__progress-bar {
    height: 8px;
    width: 100%;
    background-color: $purple400;
    border-radius: 10px;
    &--item {
      background-color: $navy500;
      border-radius: 10px;
      height: 8px;
    }
  }
}
