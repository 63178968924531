@import './components/Department.scss';
@import './components/DeleteDepartment.scss';

.admin-learning-page {
  width: 100%;
  z-index: 2;
  padding-bottom: 40px;
  background: #fafaff;
  flex: 1 1;
  padding: 22px 0 0 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  @include mobile-only {
    border-radius: 0;
    padding-bottom: 20px;
  }
  &__top {
    color: $navy500;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 112%;
    letter-spacing: -0.2px; 
  }
  &__dashboard__category {
    &--small {
      padding: 0;
      width: 40px;
      max-width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &--big {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 40px;
      @include mobile-only {
        padding: 0 20px;
      }
    }
  }
  &__add-button {
    border-radius: 6px;
    max-width: 192px;
    margin-left: auto;
    height: 40px;
  }
  &__departments {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;

  }
  .pagination {
    margin-top: 20px;
  }
}

.admin-learning-page-container {
  .dashboard__container {
    padding: 0 70px;
    @include mobile-only {
      padding: 0 16px;
    }
  }
}