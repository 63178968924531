@import './components/EditUserModal.scss';
@import './components/AddUserModal.scss';
@import './components/RestoreUserModal.scss';
@import './components/SingleUserMobileExpand.scss';

.users-list {
  width: 100%;
  z-index: 2;
  padding-bottom: 40px;
  background: #fafaff;
  border-radius: 8px 8px 0px 0px;
  flex: 1;
  padding-top: 44px;
  @include mobile-only {
    border-radius: 0;
    padding-top: 20px;
  }
  &__table-wrapper {
    @include lg {
      min-height: 664px;
    }
  }
  &__thead {
    color: black;
    height: 32px;
    @include mobile-only {
      display: none;
    }
  }
  &__pagination {
    margin-top: 32px;
  }
  &__table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 4px;
    @include mobile-only {
      border-spacing: 0 4px;
    }
  }
  &__avatar {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    @include lg {
      display: block;
      margin-top: 10px;
    }
  }
  &__td {
    text-align: left;
    vertical-align: middle;
    border-collapse: collapse;
    // height: 56px;
    font-weight: 400;
    font-size: 14px;
    line-height: 134%;
    color: #393c73;
    font-weight: 500;
    background-color: #f0efff;
    @include mobile-only {
      vertical-align: top;
      span {
        display: flex;
        margin-top: 16px !important;
      }
    }
    &--capitalize {
      &:first-letter {
        text-transform: capitalize;
      }
    }
    &--suspended {
      color: #808080;
    }
    &--inactive {
      color: #808080;
    }
    &--name {
      display: flex;
      align-items: center;
      gap: 12px;
      span {
        max-width: 220px;
        padding-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 0 !important;
      }
      .svg {
        min-width: 32px;
      }
      @include mobile-only {
        span {
          display: none;
        }
      }
    }
    &--image {
      display: flex;
      align-items: center;
      height: 53px;
    }
    &:first-child {
      padding-left: 25px;
      width: 73px;
      border: solid transparent;
      border-style: none solid solid none;
      border-radius: 8px 0 0 8px;
      white-space: pre;
      @include lg {
        width: 100%;
        padding-left: 37px;
        // max-width: 266px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // margin-top: 12px;
      }
      @include mobile-only {
        width: auto;
      }
    }
    &:last-child {
      padding-right: 14px;
      text-align: right;
      border: solid transparent;
      border-style: none solid solid none;
      @include lg {
        padding-right: 27px;
        height: 56px;
      }
    }
    &:nth-child(2) {
      width: 12%;
      @include mobile-only {
        width: auto;
        max-width: 100px;
      }
    }    
    &:nth-child(3) {
      width: 20%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 200px;
      padding-right: 15px;
      @include mobile-only {
        width: 130px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

    }  
    &:nth-child(4) {
      width: 15%;
      @include mobile-only {
        display: none;
      }
    }
    &:nth-child(5) {
      width: 15%;
      @include mobile-only {
        display: none;
      }
    }
  }

  &__send-activate-link {
    font-weight: 600;
    font-size: 12px;
    line-height: 126.9%;
    letter-spacing: -0.02em;
    color: $purple500;
    text-align: right;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    &--show {
      opacity: 1;
      pointer-events: all;
    }
  }
  &__stats {
    path {
      stroke: $navy300;
    }
  }
  &__tr {
    margin-bottom: 8px;
    transition: 0.3s;
    height: 0;
    position: relative;
    @include mobile-only {
      border-radius: 8px;
    }
    @include lg {
      height: 56px;
    }
    &--inactive {
      .users-list__td {
        color: $navy200;
        background-color: rgba(245, 244, 255, 1);
      }
    }
    &--expand {
      transition: 0.3s;
      height: 153px;
      opacity: 1;
      .users-list__td {
        vertical-align: top;
        // padding-top: 19px;
        &:first-child{
          padding-top: unset;
        }
      }
    }
    &--expand-avatar {
      transition: 0.4s;
      height: 142px !important;
    }
  }

  &__add-user-mobile {
    margin-bottom: 16px;
    max-width: 100%;
    .btn {
      width: 100%;
    }
  }
  &__add-user-button {
    width: 192px;
    height: 40px;
  }
  &__menu {
    gap: 16px;
    justify-content: flex-end;
    height: 50px;
    padding-right: 10px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    @include mobile-only {
      display: none;
    }
    .svg {
      cursor: pointer;
    }
    &--refresh {
      margin-right: -4px;
    }
    .menu {
      display: flex;
      align-items: center;
      justify-content: space-around;
      &--admin {
        justify-content: flex-end;
        padding-right: 10px;
      }
    }
  }

  th {
    font-weight: 400;
    font-size: 14px;
    line-height: 126.9%;
    text-align: start;
    color: #393C73;
    vertical-align: top;
    padding-bottom: 20px;
    &:first-child {
      padding-left: 11px;
      @include lg {
        padding-left: 37px;
      }
    }
    &:last-child {
      width: 100px;
    }
  }
  &__badge {
    background: #d9d9d9;
    border-radius: 20px;
    padding: 0px 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: min-content;
    font-weight: 400;
    font-size: 10px;
    line-height: 134%;
    color: $gray800;
    &--suspended {
      background: #f2f2f2;
    }
    &--active {
      background-color: #a0e9d8;
      color: $gray800;
    }
  }
  &__tr-admin {
    @include mobile-only {
      .users-list__td {
       &:first-child {
         span {
           display: block;
         }
       }
       &:nth-child(3) {
         display: none;
       } 
       &:nth-child(2) {
         border-top-right-radius: 8px;
         border-bottom-right-radius: 8px;
       } 
      }
    }
  }
  &--pagination {
    margin-top: 40px;
    @include mobile-only {
      margin-top: 32px;
    }
  }
}
