.back {
  display: flex;
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  align-items: center;
  gap: 10.5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #6a65de;
  padding: 0;
}
