.lesson-explanation {
  border-radius: 6px;
  background: #FBFBFF;
  box-shadow: 0px 4px 20px 0px rgba(34, 36, 80, 0.08);
  max-width: 503px;
  margin-right: auto;
  max-height: 0;
  padding: 0 25px;
  transition: 0.3s;
  opacity: 0;
  margin-top: 20px;

  &--active {
    transition: 0.3s;
    max-height: 1200px;
    padding: 30px 25px;
    opacity: 1;
  }
  @include mobile-only {
    margin-right: unset;
    max-width: 100%;
  }
  &__title {
    color: #6367A6;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 126.9%; 
  }
  &__description {
    margin-top: 20px;
  }
  &__image {
    max-width: 100%;
    margin-top: 20px;
    border-radius: 6px;

  }
  &__youtube {
    margin-top: 20px;
    border-radius: 6px;
    overflow: hidden;
  }
}