.home-page {
  height: 100vh;
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 126.9%;
    color: #000000;
  }
  &__header {
    width: 100%;
    font-weight: 800;
    font-size: 36px;
    line-height: 126.9%;
    margin-top: 15px;
  }
  &__description {
    margin-top: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 126.9%;
    color: #4a4a4a;
  }
  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  &__buttons {
    width: 100%;
    margin-top: auto;
    .btn {
      &:first-child {
        margin-bottom: 20px;
      }
    }
  }
  &__right {
    padding: 0 16px;
    padding-bottom: 59px;
    padding-top: 100px;
    flex: 1;
    background: linear-gradient(14.59deg, #03041a 0%, #222450 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    @include mobile-only {
      flex: unset;
      // height: 149px;
      width: 100%;
      height: 100%;
    }
  }
  &__logo {
    height: 81px;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 125%;
    transform: rotate(0.1deg);
    color: $purple300;
    margin-top: 85px;
    margin-bottom: 35px;
    padding-right: 5px;
    text-align: center;
  }
  &__right-container {
    max-width: 323px;
  }

  &__list-item {
    color: $purple200;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    align-items: center;
    .svg {
      margin-right: 14px;
      min-width: 24px;
    }
    margin-bottom: 16px;
  }

  &__animated-element {
    opacity: 0.5;
    filter: blur(60px);
    transform: rotate(17.45deg);
    position: absolute;
    width: 595.5px;
    height: 595.5px;
    display: flex;
    flex: 1;
    &--first {
      animation: animatied-first 15s infinite;
      transform: rotate(99deg);
      animation-timing-function: ease-in-out;
    }
    &--second {
      animation: animatied-second 25s infinite;

      transform: rotate(222deg);
    }
    @include mobile-only {
      display: none;
    }
  }
}
