.top-towns {
  background-color: #EFEEFF;
  max-width: 608px;
  padding: 20px 27px 52px 42px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 351px;
  @include mobile-only {
    max-width: 100%;
    padding-bottom: 25px;
  }
  &--skeleton {
    max-width: 608px;
  }
  &__top {
    display: flex;
    align-items: center;
    position: relative;
    span {
      color: $navy400;
      text-align: center;
      font-family: Jost;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      width: 100%;
      text-align: center;
    }
    .btn {
      max-height: 32px;
      max-width: 127px;
      @include mobile-only {
        display: none;
      }
    }
    .svg {
      height: 40px;
      width: 40px;
    }
  }
  &__bottom {
    margin-top: 52px;
    display: flex;
     @include mobile-only {
        flex-direction: column;
        justify-content: center;
        .recharts-wrapper {
          margin: 0 auto 39px auto;
        }
        .btn {
          max-height: 40px;
          margin-top: 32px;
        }
      }
  }
  &__list {
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
     @include mobile-only {
      margin-left: unset;
    }
  }
  &__list-item {
    display: flex;
    justify-content: space-between;
    color: $navy400;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin-bottom: 14px;
    &--left,&--right {
      display: flex;
      align-items: center;
    }
    &--right {
     width: 150px;
     justify-content: space-between;
    }
    &:nth-child(1) {
      .top-towns__list-item-color {
        background-color: rgba(109, 104, 253, 1);
      }
    }
    &:nth-child(2) {
      .top-towns__list-item-color {
        background-color: rgba(176, 173, 254, 1);
      }
    }
    &:nth-child(3) {
      .top-towns__list-item-color {
        background-color: rgba(99, 103, 166, 1);
      }
    }
    &:nth-child(4) {
      .top-towns__list-item-color {
        background-color: rgba(191, 143, 38, 1);
      }
    }
    &:nth-child(5) {
      .top-towns__list-item-color {
        background-color: rgba(242, 188, 70, 1);
      }
    }
    &:nth-child(6) {
      .top-towns__list-item-color {
        background-color: rgba(42, 38, 153, 1);
      }
    }
  }

  &__list-item-color {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    margin-right: 14px;
  }
  &__circle {
    position: relative;
  }
  &__circle-center {
    position: absolute;
    z-index: 999;
    display: flex;
    flex-direction: column;
    text-align: center;
    top: 60px;
    left: 55px;
    @include mobile-only {
      left: 50%;
      transform: translate(-50%);
    }
    span {
      color: $navy300;
      font-family: Jost;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */ 
    }
    p {
      color: $navy500;
      font-family: Jost;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 16.8px */ 
    }
  }
}