
.question {
  display: flex;
  justify-content: space-between;
  @include mobile-only {
    flex-direction: column;
  }
  &__left, &__right {
    width: 50%;
    @include mobile-only {
      width: 100%;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    &--media {
      max-width: 608px;
      @include mobile-only { 
        max-width: calc(100vw - 32px);
      }
    }
    &--explanation {
      background-color: $purple300;
      padding: 28px 20px;
      border-radius: 6px;
      .question__label {
        max-width: 100%;
      }
    }
    .btn--stroke {
      @include mobile-only {
        max-height: 40px;
        margin-top: 20px;
        .svg {
          margin-right: 6px;
        }
        .svg path {
          fill: #6d68fd;
        }
      }
    }
  }
  &__label {
    font-weight: 700;
    font-size: 16px;
    color: $navy400;
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
    max-width: 500px;
    &--icon {
      cursor: pointer
    }
  }
  &__link {
    text-decoration: none;
    color: $purple600;
  }
  &__paragraph {
    font-weight: 400;
    font-size: 14px;
    color: $navy400;
    &--max500 {
      max-width: 500px;
    }
    &--link {
      margin-top: 16px;
    }
    &--file {
      margin-top: 12px;
      max-width: 360px;
      max-height: 257px;
    }
  }
  &__photo {
    span {
      cursor: pointer;
    }
    svg {
      height: unset;
    }
  }
}