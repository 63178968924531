.lesson-item {
  &__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @include mobile-only {
      margin-bottom: 8px;
    }
  }
  &__counter {
    margin-right: 13px;
    color: $navy400;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 126.9%; 
    @include mobile-only {
      margin-right: 10px;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: $purple300;
    padding: 18px 20px;
    border-radius: 8px;
    justify-content: space-between;
    @include mobile-only {
      padding: 16px 0 16px 8px;
    }
  }
  &__name {
    color: $navy600; 
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 126.9%; 
  }
  &__actions {
    display: flex;
    margin-right: 32px;
    @include mobile-only {
      display: none;
    }
    &--single {
      background-color: rgba(236, 235, 255, 1);
      border-radius: 4px;
      display: flex;
      align-items: center;
      width: 32px;
      height: 32px;
      justify-content: center;
      cursor: pointer;
      &:first-child {
        margin-right: 6px;
      }
      .svg {
        width: 20px;
        path {
          fill: $navy400;
        }
      }
      .edit {
        margin-left: -3px;
        margin-top: -2px;
      }
    }
  }
  &__counter {
    display: flex;
    min-width: 54px;
  }
  &__counter-number {
    color: $navy600;
    font-family: Jost;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 126.9%; 
    display: flex;
    align-items: center;
  }
  &--right {
    display: flex;
  }
  &__counter-chevron {
    width: 32px;
    height: 32px;
    margin-left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transform: rotate(270deg);
    &--active {
      transform: rotate(90deg);
    }
  }

  &--left {
    display: flex;
    align-items: center;
  }
  &__ellipsis {
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    position: relative;
  }
  &__menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: -8px;
    width: 173px;
    border-radius: 6px;
    box-shadow: 0px -2px 20px 0px rgba(17, 19, 56, 0.20);
    background-color: rgba(255, 255, 255, 1);
    top: 35px;
    z-index: 9;
    height: 0;
    overflow: hidden;
    transition: 0.3s;

    &--active {
      height: 96px;
      transition: 0.3s;
    }
    &--item {
      padding: 14px 20px 14px 20px; 
      color: $navy500;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 126.9%;
      letter-spacing: -0.16px; 
      transition: 0.3s;
      &:hover {
        color: $navy600;
        transition: 0.3s;
        background-color: $purple200;
      }
    }
  }
}