.recommended-to-others {
  display: flex;
  justify-content: center;
  padding: 0 !important; 
  @include mobile-only {
    min-height: calc(100vh - 280px) ;
  }
  &__content {
    max-width: 398px;
    .btn {
      max-height: 48px;
      margin-top: 15px;
    }
  }
  &__heading {
    color: $navy700;
    text-align: center;
    font-family: Jost;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 126.9%; /* 30.456px */
    letter-spacing: -0.48px; 
    margin-top: 45px;
  }
  &__description {
    color: $navy400;
    text-align: center;
    font-family: Jost;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 126.9%; 
    margin-top: 15px;
    margin-bottom: 61px;
  }
  &__link {
    &--wrapper {
      display: flex;
      flex-direction: column;
    }
  }
  &__link-heading {
    color: $navy600;
    font-family: Jost;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 126.9%; /* 17.766px */ 
  }
  &__link-copy {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      .svg {
        scale: 1.1;
        transition: 0.1s;
      }
    }
  }
  &__link {
    flex: 1;
    position: relative;
    height: 48px;
    padding: 11px 50px 11px 20px;
    gap: 8px;
    border-radius: 5px;
    background: #EEEDFF; 
    margin-top: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
  .copied-alert {
    padding: 0 20px;
    border-radius: 5px;
    background: rgba(34, 220, 153, 0.6); 
    width: 100%;
    margin-top: 15px;
    text-align: center;
    height: 0;
    opacity: 0;
    color: transparent;
    transition: 0.3s;
    &--active {
      height: 48px;
      opacity: 1;
      padding: 11px 20px;
      transition: 0.3s;
      color: $navy400;
    }
  }
}