.save-tasks {
  &__table {
    width: 100%;
    border-collapse: initial;
    border-spacing: 0 8px;
  }
  &__thead {
    @include mobile-only {
      display: none;
    }
  }
  &__th {
    text-align: left;
    color: #393C73;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 126.9%;
    &--name {
      padding-left: 27px;
      width: 10%;
    }
    &--type {
      width: 10%;
    }
    &--points {
      width: 10%
    }
    &--question {
      width: 70%;
    }
  }
  &--margin {
    margin-top: 16px;
  }
}