.checkbox {
  input {
    display: none;
  }

  &__checkmark {
    min-width: 20px;
    min-height: 20px;
    height: 20px;
    width: 20px;
    margin-right: 20px;
    background: #ffffff;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    .svg {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      margin-left: -2px;
    }
  }
  input:checked ~ &__checkmark .svg {
    opacity: 1;
  }
  &__error {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: $error;
    height: 12px;
    font-size: 12px;
  }
}
