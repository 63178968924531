.login-page {
  width: 100%;
  margin: 0 20px;
  display: flex;
  height: unset;
  padding-bottom: 59px;
  @include lg {
    width: 342px;
    margin: 0 auto;
    margin: auto;
  }
  &__title {
    font-weight: 900;
    font-size: 24px;
    line-height: 126.9%;
    color: $navy700;
    margin-top: 26px;
    text-align: center;
    position: relative;
    span {
      position: absolute;
      left: 0;
      cursor: pointer;
      svg path {
        stroke: rgba(106, 101, 222, 1);
      }
    }
  }
  &__error {
    background: #ffe1e1;
    margin-bottom: -32px;
    border-radius: 8px;
    padding: 0 19px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $navy700;
    letter-spacing: -0.01em;
    font-weight: 400;
    font-size: 14px;
    line-height: 126.9%;
    opacity: 0;
    transition: 0.3s;
    margin-top: 8px;
    &--show {
      margin-top: 32px;
      opacity: 1;
      padding: 12px 19px;
    }
    &--sms-code {
      margin-bottom: 10px;
    }
  }
  &__error-category {
    opacity: 0;
    width: 100%;
    transition: 0.3s;
    color: $error;
    margin-top: -24px;
    margin-bottom: 4px;
    &--visible {
      opacity: 1;
    }
  }
  &__content-wrapper {
    width: 100%;
    position: relative;
  }
  &__description {
    margin-top: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 126.9%;
    color: #4a4a4a;
    text-align: center;
    font-weight: 400;
    color: $navy400;
    @include lg {
      margin-top: 24px;
    }
    &--successful-password-link-send {
      font-size: 14px;
      font-weight: 500;
      b {
        font-weight: 600;
      }
    }
    &--successful-password-change {
      font-size: 14px;
      font-weight: 500;
      @include lg {
        margin-bottom: 128px;
      }
    }
  }
  &__reset-password-wrapper {
    position: relative;
  }
  &__reset-password {
    position: absolute;
    right: 0;
    top: 2px;
    font-weight: 500;
    font-size: 12px;
    line-height: 126.9%;
    text-align: right;
    letter-spacing: -0.01em;
    color: $purple500;
    cursor: pointer;
    z-index: 2;
  }
  &__send-link-again {
    font-weight: 400;
    font-size: 14px;
    line-height: 126%;
    color: $navy400;
    text-align: center;
    margin-bottom: 40px;
    @include lg {
      margin-top: 104px;
      margin-bottom: 26px;
    }
  }
  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &--without-flex {
      flex: unset;
    }
  }
  &__input {
    width: 100%;
    position: relative;
    .input {
      font-weight: 400;
      font-size: 16px;
      line-height: 126.9%;
      padding-left: 130px;
    }
  }
  &__form {
    width: 100%;
    margin-top: 54px;
    position: relative;
    .select__wrapper {
      position: absolute;
      z-index: 2;
      width: 120px;
      left: 4px;
      top: -12px;
      // gap: 0;
      .label {
        display: none;
      }
      .input__error {
        bottom: -26px;
      }
      .select__indicator {
        padding: 0;
        color: $purple400;
      }
      .select__control {
        background-color: #eeedff;
        border: none;
      }
      div .select__value-container {
        padding-right: 0;
        height: unset;
      }
      .select__option-container {
        color: $navy300;
        font-weight: 400;
        font-size: 16px;
        line-height: 126.9%;
        .svg--flag {
          min-width: 27px;
        }
      }
    }
    &--phone {
      .input__error {
        bottom: -20px;
      }
    }
    .sms-code-input__wrapper {
      margin-top: 50px;
    }
    &--admin {
      .input {
        padding-left: 20px;
      }
    }
  }
  &__terms {
    font-weight: 400;
    font-size: 12px;
    line-height: 126.9%;
    color: $navy400;
    a {
      margin: 0 4px;
      color: $purple500;
      text-decoration: none;
      font-weight: 600;
      cursor: pointer;
    }
  }
  &__curly-arrow {
    position: absolute;
    top: 10px;
    right: -85px;
    height: auto;
    @include mobile-only {
      display: none;
    }
  }
  &__list {
    @include mobile-only {
      display: none;
    }
  }
  &__tooltip {
    position: absolute;
    top: -165px;
    right: -278px;
    z-index: 1;
    height: auto;
    padding: 24px;
    width: 220px;
    background: $navy400;
    // box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    color: #fbfbff;
    font-weight: 400;
    font-size: 12px;
    line-height: 126.9%;
    b {
      font-weight: 600;
    }
    .svg {
      display: none;
    }
    @include mobile-only {
      position: relative;
      right: 0;
      top: 54px;
      width: 100%;
      background-color: $purple300;
      color: #0a0a14;
      margin-bottom: 32px;
      b {
        font-weight: 700;
      }
      .svg {
        position: absolute;
        top: -16px;
        left: 24px;
        display: block;
        height: auto;
      }
    }
  }
  &__top-wrapper {
    position: relative;
    .back {
      position: absolute;
      left: -25px;
      top: 8px;
      color: #6a65de;
      @include mobile-only {
        top: -155px;
        left: 0;
      }
    }
  }

  &__checkbox {
    margin-top: 64px;
    margin-bottom: 15px;
    .input__label {
      @include mobile-only {
        margin-top: 20px;
      }
    }
    .checkbox__checkmark {
      margin-top: -6px;
    }
  }
  &__resend-code {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 126.9%;
    text-align: center;
    color: $navy400;
    margin-top: 72px;
    b {
      font-weight: 700;
      color: $purple500;
      cursor: pointer;
    }
  }
  &__buttons {
    margin-bottom: -15px;
    width: 100%;
    .btn {
      max-height: 48px;
    }
    .btn--stroke {
      margin-bottom: 12px;
    }
    // @include mobile-only {
    //   margin-bottom: 240px;
    // }
  }
  &--token-expired {
    @include mobile-only {
      height: 100%;
    }
    .login-page__title {
      margin-bottom: 35px;
    }
    .login-page__description {
      margin-top: 0;
      margin-bottom: 128px;
    }
    .login-page__buttons {
      @include mobile-only {
        margin-top: auto;
      }
    }
    .login-page__body {
      @include mobile-only {
        height: 100%;
      }
    }
  }
}
.validation-item {
  &:last-of-type {
    margin-bottom: 24px;
  }
}
