.answersModal {
  justify-content: flex-start;
  padding: 40px 0 0 0;
  @include mobile-only {
    padding-top: 0;
  }
  @include lg {
    padding: 40px
  }
  &__content {
    height: auto !important;
    max-width: 826px;
    width: 100%;
    position: relative;
    @include lg {
      padding: 0;
    }
  }
  &__heading {
    color: $navy700;
    font-size: 16px;
    font-weight: 600;
    line-height: 126.9%;
    margin-bottom: 40px;
    margin-left: 40px;
    @include mobile-only {
      margin-top: 20px;
    }
    @include lg {
      margin-left: 0;
    font-size: 20px;
    }
    .svg {
      margin-right: 15px;
      @include lg {
        margin-right: 30px;
      }
      path {
        stroke: rgba(106, 101, 222, 1);
      }
    }
    span {
      cursor: pointer;
      margin-left: -35px;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
  }
}

.answersModal-item {
  display: flex;
  border-radius: 10px;
  background: #EBEBFF; 
  padding: 18px 24px;
  margin-bottom: 12px;
  justify-content: space-between;
  &--premium {
    cursor: pointer;
    &:hover {
      background-color: $purple300;
    }
  }
  &--left {
    display: flex;
    flex-direction: column;
  }
  &--right {
    display: flex;
    align-items: center;
    margin-left: 15px;
    .svg {
      width: 32px;
      height: 32px;
    }
  }
  &__question {
    color: $navy500;
    font-size: 14px;
    font-weight: 500;
    line-height: 126.9%; 
    margin-bottom: 12px;
    max-width: 592px;
    @include lg {
    font-size: 16px;
    }
  }
  &__others {
    display: flex;
    div {
      color: $navy300;
      font-size: 12px;
      line-height: 126.9%; 
      margin-right: 20px;
      @include lg {
       font-size: 14px;
      }
      &:first-child {
        min-width: 22px;
      }
    }
  }
}

.singleAnswer {
  display: flex;
  max-width: 1248px;
  width: 100%;
  flex-direction: column;
  position: relative;
  @include mobile-only {
    overflow: auto;
    height: 100%;
    padding-bottom: 170px;
    // min-height: 100vh;
  }
  @include lg {
    justify-content: space-between;
    flex-direction: unset;
    
  }
  &__left {
    width: 100%;
    // height: 100%;
    @include mobile-only {
      // margin-bottom: 200px;
    }
    @include lg {
      max-width: 827px;
      margin-right: 20px;
    }
  }
  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    padding: 0 20px;
    flex-wrap: wrap;
    @include lg {
      padding: 0;
    }
    @include mobile-only {
      padding-top: 20px;
    }
    div {
      &:nth-child(n + 2) {
        color: $navy400;
        font-size: 18px;
        font-weight: 500;
        line-height: 126.9%; 
      }
    }
    &--others {
      display: flex;
    }
  }
  &__goBack {
    cursor: pointer;
    color: $purple500;
    font-size: 18px;
    font-weight: 500;
    line-height: 126.9%; 
    display: flex;
    width: 100%;
    margin-bottom: 25px;
    @include lg {
      margin-left: 10px;
      margin-bottom: 0;
      width: unset;
    }
    span {
      display: flex;
      align-items: center;
    }
    .svg {
      margin-right: 10px;
    }
  }
  &__media {
    @include lg {
      max-width: 827px;
      max-height: 465px;
    }
    img {
      object-fit: cover;
      height: 100%;
      max-height: 230px;
      width: 100%;
      @include lg {
        max-width: 827px;
        max-height: 465px;
      }
    }
  }
  &--basic {
    display: flex;
    padding: 0 20px;
    @media(max-width: 765px) {
      justify-content: space-between;
      .singleAnswer__box {
        width: calc(50% - 8px);
        &:first-child {
          margin-right: 16px;
        }
      }
    }
    @include lg {
      padding: 0;
    }
  }
  &--special {
    display: flex;
    flex-direction: column;
  }
  &-special--item {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    position: relative;
    svg {
      position: absolute;
      top: -15px;
      width: 32px;
      height: 32px;
      left: 35px
    }
  }
  &__box {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    transition: 0.3s;
    border-radius: 8px;
    position: relative;
    &--special {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(168, 186, 255, 1);
      color: #fff;
      margin-right: 20px;
      &--checked {
        background-color: rgba(4, 28, 111, 1);
      }
      
    }
    svg {
      position: absolute;
      top: -15px;
      right: -15px;
      width: 32px;
      height: 32px;
    }
    &--simple {
      width: 72px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba(255, 255, 255, 1);
      &:first-child {
        margin-right: 56px;
      }
    }
    &--answer {
      background-color: #041C6F;
    }
    &--wrong-answer {
      background-color: rgba(168, 186, 255, 1)
    }
  }
  &__question {
    color: $navy600;
    font-size: 16px;
    font-family: Jost;
    font-style: normal;
    font-weight: 500;
    line-height: 126.9%; 
    margin-top: 27px;
    margin-bottom: 32px;
    padding: 0 20px;
    @include lg {
      padding: 0;
    }
  }
  &__pagination {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 64px;
    box-shadow: 0px -2px 25px 0px rgba(17, 19, 56, 0.12); 
    align-items: center;
    width: 100%;
    z-index: 99;
    background-color: #fff;
    @include lg {
      box-shadow: unset;
      height: unset;
      margin-bottom: 30px;
      position: unset;
    }
  }
  &__pagination-item {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 138%; 
    cursor: pointer;
    border-radius: 6px;
    -webkit-tap-highlight-color: transparent !important;
    -moz-tap-highlight-color: transparent!important;
    -ms-tap-highlight-color: transparent!important;
    -webkit-tap-highlight-color: transparent !important;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    
    @include lg {
      width: 30px;
      height: 30px;
    }
    &--corectly {
      color: rgba(0, 180, 115, 1);
      &.singleAnswer__pagination-item--active {
        background-color: rgba(211, 244, 232, 1);
      }
    }
    &--wrong {
      color: rgba(255, 108, 108, 1);
      &.singleAnswer__pagination-item--active {
        background-color: rgba(255, 225, 225, 1)
      }
    }
    &__back {
      margin-right: 10px;
      svg path{
        stroke: rgba(106, 101, 222, 1);
      }
    }
    &__next {
      margin-left: 10px;
      svg {
        transform: rotate(180deg);
        path {
          stroke: rgba(106, 101, 222, 1);
        }
      }
    }
    &--disabled {
      pointer-events: none;
      svg path{
        stroke: $purple300
      }
    }
  }
  &__right {
    border-radius: 6px;
    background: #FBFBFF;
    box-shadow: 0px 4px 20px 0px rgba(34, 36, 80, 0.08); 
    margin: 40px 20px 0 20px;
    padding: 30px;
    // height: 100%;
    @include lg {
      width: 100%;
      max-width: 300px;
      margin: 130px 0 0 0;
      margin-bottom: auto;
      height: auto;
    }
    &--explanation {
      margin-bottom: 30px;
    }
    &--photo {
      img {
        max-width: 100%;
        height: auto;
        margin-bottom: 25px;
      }
    }
  }
  &__right-heading {
    color: #6367A6;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 126.9%; 
    margin-bottom: 30px;
  }
  &__answers {
    @include mobile-only {
      padding: 0 20px;
    }
  }
}