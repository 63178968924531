$darkBlue: #222d5c;

$primaryFont: 'Jost', sans-serif;

$white: #fff;
$black: #000;

$lightGray: #ebebeb;
$gray-1: #767676;
$gray-2: #cecece;
$gray-3: #666;
$gray-4: #929292;
$gray-5: #f3f3f3;
$gray-6: #cdcdcd;
$gray-7: #dbdcdb;
$midGray: #d9d9d9;
$darkGray: #acacac;
$darkerGray: #5c5c5c;
$textColor: #494949;

$gray700: #404040;
$gray800: #2d2d2d;

$green500: #2eb092;
$green600: #086852;

$navy200: #adb0d9;
$navy300: #6367a6;
$navy400: #393c73;
$navy500: #222450;
$navy600: #111338;
$navy700: #03041a;

//fioletowy
$purple200: #fafaff;
$purple300: #e1e0ff;
$purple400: #b0adfe;
$purple500: #6d68fd;
$purple600: #514CD9;

$gold: #d1b978;

$error: #db4446;
$goldGradient: linear-gradient(90deg, #fbc777 -16.09%, #bb9b63 84.38%, #fde4ab 206.37%, #ac8243 342.72%);
$green: #2d3f25;
$text: #282828;
$background1: #f4f4f4;
$background2: #e0e2df;
$inputDefault: #a3a4a3;
$disabled: #acacac;

// Screen sizes
$screen-sm: 420px;
$screen-md: 756px;
$screen-lg: 1024px;

