.admin-department-header {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  &__content {
    max-width: 1240px;
    width: 100%;
    padding: 0 16px;
    @include mobile-only {
      padding-left: 50px;
      padding-right: 16px;
    }
  }
  &__heading {
    color: #FBFBFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 126.9%; 
    min-width: 300px;
    display: flex;
    align-items: center;
    span {
      width: 30px;
      height: 30px;
      display: block;
      margin-left: -30px;
      cursor: pointer;
    }
  }
  &__counters {
    color: $purple300;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 126.9%; 
    @include mobile-only {
      display: none;
    }
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__department-name {
    color: $navy200;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 112%;
    letter-spacing: -0.14px; 
    max-width: 360px;
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  &__department-badge {
    @include mobile-only {
      display: none;
    }
  }
}