.daily-limit {
  max-width: 400px;
  &--header {
    text-align: center;
  }
  &__description {
    color: $navy400;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 20px;
    b {
      font-weight: 600;
    }
  }
  &__icons {
    text-align: center;
    display: block;
    width: 100%;
    font-size: 48px; 
    margin-bottom: 50px;
  }
  &__buttons {
    display: flex;
    column-gap: 16px;
    max-height: 40px;
    max-width: 500px;
    width: 100%;
    @include mobile-only {
      margin-top: auto;
      margin-bottom: 20px;
      .btn {
        padding: 10px 0;
      }
    }
  }
}