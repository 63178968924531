.basic-question-buttons {
  display: flex; 
  width: 100%;
  margin-bottom: 18px;
  @include mobile-only {
    flex-direction: column;
  }
}

.basic-question-button{
  max-width: 191px;
  width: 100%;
  padding: 17px 17px 17px 28px;
  border-radius: 8px;
  background-color: $purple300;
  color: $navy600;
  cursor: pointer;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 112%;
  letter-spacing: -0.2px; 
  transition: 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 58px;
  @include mobile-only {
    max-width: 100%;
  }
  &:first-child {
    margin-right: 17px;
    @include mobile-only {
      margin-right: 0;
      margin-bottom: 12px;
    }
  }
  &--choice {
    transition: 0.3s;
    background-color: $navy600;
    color: #fff;
  }
  &--disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
  &--green {
    background-color: #22DC99;
  }  
  &--red {
    background-color: #FF6C6C;
  }
}

