.user-statistics {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 400px;
  width: 100%;
  @media(max-width: 756px) {
    max-width: 100%;
    margin-bottom: 20px;
    &:nth-child(3) {
      .user-statistics__heading {
        display: none;
      }
    }
  }
  &__categories {
    margin-bottom: -28px !important;
  }
  &__wrapper {
    padding-top: 30px;
  }
  &--page-title {
    color: #FBFBFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 126.9%; 
    margin-top: -45px;
  }
  &__content {
    display: flex;
    gap: 16px;
    @media(max-width: 756px) {
      flex-direction: column;
      padding-bottom: 70px;
    }
  }
  &__heading {
    color: $navy400;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 20px;
  }
  &__container {
    margin-top: 30px;
  }
  &__box {
    background-color: #EFEEFF;
    border-radius: 6px;
    padding: 19px 0 77px 0;
    display: flex;
    flex-direction: column;
    position: relative;
    &--exam {
      background-color: #E1E0FF;
    }
  }
  &__label {
    text-align: center;
    color: $navy400;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; 
    margin-bottom: 33px;
  }
  &__numbers {
    color: $navy400;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 4px;
    &--exam {
      color: $navy500;
    }
  }
  &__number-text {
    color: $navy200;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-align: center;
    margin-bottom: 66px;
    &--exam {
      color: $navy400;
    }
  }
  &__circle {
    width: 144px;
    height: 144px;
    margin: 0 auto;
    position: relative;
    .CircularProgressbar-text  {
      font-size: 20px !important;
      font-weight: 700;
      dominant-baseline: middle;
      text-anchor: middle;
    }
    margin-bottom: 13px;
  }
  &__procent-text {
    color: $navy300;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-align: center; 
    &--exam {
      color: $navy400;
    }
  }
  &__number-icon {
    font-size: 60px;
    position: absolute;
    bottom: -50px;
    left: 40px
  }
  &--skeleton {
    .user-statistics__box {
      max-height: 432px !important;
      height: 100% !important;
      padding: 0;
      background-color: unset !important;
    };
  }
}