.input {
  background: #eeedff;
  border-radius: 5px;
  padding: 0 18px;
  height: 48px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  margin-bottom: 24px;
  color: $navy400;
  font-weight: 400;
  border: 1px solid transparent;
  transition: 0.2s;
  &:focus {
    transition: 0.2s;
    border-color: $purple500; /* Kolor obramowania po kliknięciu */
    outline: none; /* Usuwa domyślną kontur po kliknięciu w niektórych przeglądarkach */
  }
  &::placeholder {
    color: $navy300;
  }
  &__textarea {
    resize: none;
    padding: 11px 20px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
  }
&__label {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: $black;
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 8px;
  }
  &__error {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: $error;
    position: absolute;
    bottom: 6px;
  }
  &__optional {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: $gray-1;
  }
  &__double-input {
    display: flex;
    gap: 10px;
    input:first-child {
      max-width: 30%;
    }
    input:last-child {
      max-width: 70%;
    }
  }
  &__change-password {
    position: absolute;
    right: 20px;
    top: 37px;
    svg path {
      fill: rgba(176, 173, 254, 1);
    }
  }
}
input[type='number'] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
}
input[type='number']:hover,
input[type='number']:focus {
  -moz-appearance: textfield;
  appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.react-datepicker-popper, .react-datepicker-wrapper {
  z-index: 99999 !important;
}