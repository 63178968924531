.restore-user-modal {
  &__header {
    font-weight: 700;
    font-size: 20px;
    line-height: 126.9%;
    color: $navy700;
    margin-bottom: 40px;
  }
  &__description {
    width: 100%;
    margin-bottom: 10px;
    color: $navy600
  }
  &__user {
    width: 100%;
    color: $navy600;
    font-weight: 500;
  }
  &__buttons {
    width: 100%;
    display: flex;
    gap: 8px;
    margin-top: 40px;
    .btn {
      height: 40px;
    }
  }
}