.lesson-tile {
  display: flex;
  justify-content: space-between;
  max-width: 504px;
  width: 100%;
  align-items: center;
  margin: 40px 0;
  position: relative;
  &__onclick {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  &--blocked {
    pointer-events: none;
    .lesson-tile__title, .lesson-tile__descript {
      color: $navy200;
    }
    .lesson-tile__right .svg path {
      stroke: $navy200;
    }
  }
  @include mobile-only {
    max-width: 100%;
  }
  &__left {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    margin-right: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    color: $purple500;
    font-weight: 600; 
    .react-loading-skeleton {
      width: 50px;
      height: 50px;
      border-radius: 50px;
    }
    &--completed {
      background-color: $purple500;
    }
    &--blocked {
      background-color: rgba(239, 240, 255, 1);
    }
    &--open {
      background-color: $purple300;
    }
  }
  &__mid {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    width: 100%;
  }
  &__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 112%;
    letter-spacing: -0.16px; 
    color: $navy400;
  }
  &__descript {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 112%;
    letter-spacing: -0.14px; 
    margin-top: 6px;
    color: $navy300;
    .react-loading-skeleton {
      max-width: 160px;
      height: 14px;
    }
  }
  &__right {
    width: 40px;
    display: flex;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    @include mobile-only {
      margin-bottom: auto;
      margin-top: 7px;
    }
    svg {
      transform: rotate(180deg);
      path {
        stroke: $purple500;
      }
    }
  }
}