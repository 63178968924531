.single-task {
  position: relative;
  &--skeleton {
    width: 100%;
    span {
      width: 100%;
      display: inline-block;
      margin-bottom: 4px;
    }
  }
  @include mobile-only {
    display: none;
  }
  &__icon {
    width: 32px;
    height: 32px;
    background-color: $purple600;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
    .svg path{
      fill: $purple300;
    }
  }
  &__td {
    color: $navy500;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 126.9%;
    background-color: #F0EFFF;
    padding: 20px 0;
    div {
      display: flex;
      align-items: center;
    }
    &--question {
      padding-right: 60px;
      border-radius: 0 8px 8px 0;
    }
    &--icon {
      padding: 10px 0;
      position: relative;
      width: 60px;
      border-radius: 0 8px 8px 0;
    }
    &:first-child {
      border-radius: 8px 0 0 8px;;
    }
  }
  &--name {
    padding-left: 27px;
  }
  &--onclick {
    position: absolute;
    left: 0;
    height: 100%;
    top: 0;
    width: calc(100% - 50px);
    cursor: pointer;
  }
}

.single-task-mobile {
  border-radius: 10px;
  background: #EBEBFF;
  padding: 18px 24px;
  position: relative;
  margin-bottom: 10px;
  @media (min-width: 1024px) {
    display: none;
  }
  &__top {
    max-width: 240px;
    margin-bottom: 16px;
  }
  &__icon {
    position: absolute;
    top: 18px;
    right: 24px;
    width: 32px;
    height: 32px;
    background-color: $purple600;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .svg path{
      fill: $purple300;
    }
  }
  &__bottom {
    display: flex;
    color: $navy300;
    font-family: Jost;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 126.9%;
    max-width: 240px;
    justify-content: space-between;
  }
}