@import './components/CityModal.scss';

@keyframes item-active-price {
  0% {
    background-color: #fff; /* Początkowy kolor */
  }
  100% {
    background-color: #03041A; /* Końcowy kolor */
  }
}

.myProfilePacket {
  &__buttons {
    max-height: 0;
    overflow: hidden;
    padding: 0 20px;
    transition: 0.3s;
    &--active {
      transition: 0.3s;
      max-height: 40px;
      overflow: unset;
      margin-bottom: 80px;
    }
    @include lg {
      display: flex;
      justify-content: center;
      .btn {
        max-width: 400px;
      }
    }
  }
  &__top {
    overflow: hidden;
    position: relative;
    background: var(--gradient, linear-gradient(45deg, #03041A 0%, #222450 100%));
    height: 145px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &--logo {
      position: relative;
      z-index: 99;
      margin-right: auto;
      @include lg {
        margin-left: auto;
      }
      &--back {
        position: absolute;
        left: 30px;
        cursor: pointer;
      }
    }
    &--back {
      cursor: pointer;
      margin-right: auto;
      margin-left: 9px;
      .svg path{
        stroke: #6a65de;
      }
    }
  }
  &__content {
    background-color: #fafaff;
    height: 100%;
    margin-top: -20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    overflow: hidden;
    .header {
      color: $navy700;
      font-size: 24px;
      font-family: Jost;
      font-weight: 900;
      line-height: 126.9%;
      letter-spacing: -0.24px; 
      text-align: center;
      padding: 26px 0;
    }
    .list {
      padding: 0 22px;
      @include lg {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      &__item {
        font-size: 16px;
        line-height: 115%;
        letter-spacing: -0.08px; 
        color: $navy600;
        margin-bottom: 16px;
        padding-left: 30px;
        position: relative;
        @include lg {
          max-width: 400px;
          width: 100%;
        }
      }
      .svg {
        position: absolute;
        left: -5px;
        width: 24px;
        height: 24px;
      }
    }
  }
  &__items {
    display: flex;
    @include lg {
      justify-content: center;
    }
    .slide {
      padding: 0 10px !important;
    }
    .carousel .slider-wrapper {
      padding: 62px 0;
      max-width: 100vw;
    }
    .carousel-root {
      outline: none;
      max-width: 100%;
      width: 100%;
    }
  }
  &__items-desktop {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow-x: auto;
    padding: 40px 0;
    scrollbar-width: none;
  }
  &__items-desktop::-webkit-scrollbar {
    display: none;
  }
  &__item {
    border-radius: 8px;
    height: 201px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 4px 30px 0px rgba(34, 36, 80, 0.20);
    width: 162px;
    margin-right: 20px;
    flex-shrink: 0;
    @include lg {
      &:last-child {
        margin-right: 0;
      }
    }
    &--active {
      animation: item-active-price 0.5s ease forwards; /* Animacja */
      .price, .oldPrice span {
        color: #fff;
        transition: color 0.5s ease;
      }
    }
    .active {
      border-radius: 6px;
      background: #F2BC46; 
      width: 136px;
      height: 32px;
      margin: 0 auto 15px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      .svg path {
        fill: rgba(34, 36, 80, 1);
      }
    }
    .time {
      color: $purple500;
      font-size: 18px;
      font-weight: 600;
      line-height: 112%;
      letter-spacing: -0.18px; 
      text-align: center;
      background-color: #D8D8FF;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      padding: 11px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 46px;
      .svg {
        width: 24px;
        height: 24px;
        margin-right: 4px;
      }
    }
    .oldPrice {
      text-align: center;
      color: $navy500;
      font-size: 20px;
      font-family: Jost;
      font-weight: 700;
      line-height: 112%;
      letter-spacing: -0.2px;
      span {
        padding: 0 10px;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          height: 2px;
          width: 100%;
          top: 14px;
          background-color: $purple500;
          left: 0;
        }
      }
    }
    .price {
      font-size: 36px;
      font-weight: 700;
      line-height: 112%;
      letter-spacing: -0.36px;
      text-align: center;
    }
    .btn {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.07px; 
      max-width: 136px;
      height: 32px;
      margin: 0 auto 15px auto;
    }
  }
}