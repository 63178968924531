.page404 {
  &__wrapper {
    display: flex;
    width: 100vw;
    max-height: 100vh;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(45deg, #03041A 0%, #222450 100%);
    overflow: hidden;
    position: relative;
    height: 100%;
    @include mobile-only {
      height: 100vh;
    }
  }
  &__top {
    &--left {
      position: absolute;
      top: 0;
      left: 0;
    }    
    &--right {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 150px;
    align-items: center;
    position: relative;
    .btn {
      max-width: 246px;
      margin-top: 56px;
      max-height: 48px;
    }
    @include mobile-only {
      margin-top: 100px;
      .svg--page404 {
        max-width: 300px;
      }
    }
    
  }
  &__top-logo {
    max-width: 1024px;
    width: 100%;
    margin-top: 24px;
    display: flex;
    p {
      z-index: 99999;
      cursor: pointer;
    }
    @include mobile-only {
      display: none;
    }
  }
  &__descript {
    color: #FFF;
    text-align: center;
    font-family: Jost;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%;
    @include mobile-only {
      font-size: 16px;
    }
  }
  &__cloud {
    position: absolute;
    top: -100px;
    left: 0px;
  }

}