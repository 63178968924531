.sms-code-input {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    max-width: 500px;
    text-align: center;
    margin: 0 auto;
  }
  &__square {
    width: 100%;
    aspect-ratio: 1 / 1;
    font-size: 24px;
    text-align: center;
    background: #eeedff;
    border-radius: 5px;
    border: none;
    font-weight: 600;
    font-size: 20px;
    line-height: 126.9%;
    color: $navy400;
    &--active {
      border-color: #007bff;
      box-shadow: 0 0 3px #007bff;
    }

    &--filled {
      background-color: #eee;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
