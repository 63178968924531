.switch {
  &__wrapper {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: space-between;
  }
  &__label {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: $black;
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 8px;
  }
}
.switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 46px;
  height: 24px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
  background-color: $navy200;
  &--active {
    background-color: $navy400 ;
  }
}

.switch-label .switch-button {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 16px;
  height: 16px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switch-checkbox:checked + .switch-label .switch-button {
  left: calc(100% - 4px);
  transform: translateX(-100%);
}

.switch-label:active .switch-button {
  width: 25px;
}