.add-lesson-form {
  max-width: 400px;
  width: 100%;
  @include mobile-only {
    max-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &__buttons {
    display: flex;
    margin-top: 40px;
    @include mobile-only { 
      margin-top: auto;
      margin-bottom: 100px;
    }
    .btn {
      max-height: 48px;
      padding-top: 14px;
      padding-bottom: 14px;
      &:first-child {
        margin-right: 16px;
      }
    }
  }
}