.department {
  width: 100%;
  max-width: 353px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 143px;
  padding: 20px;
  background: #ebebff;
  border-radius: 10px;
  &--skeleton {
    padding: 0;
    background: none;
  }
  @media screen and (max-width: 1410px) {
    max-width: calc(50% - 10px);
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
  }
  &__icon {
    width: 56px;
    height: 56px;
    border-radius: 8px;
  }
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 112%;
    letter-spacing: -0.01em;
    color: $navy500;
    flex: 1;
  }
  &__svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
    .svg--bin {
      margin-bottom: -7px !important;
    }
    &--chevrone {
      transform: rotate(180deg);
      path {
        stroke: $purple500;
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 16px;
  }
  &__stats {
    font-weight: 500;
    font-size: 12px;
    line-height: 126.9%;
    color: $navy400;
    @include mobile-only {
    font-size: 10px;
    }
  }
  &__badge {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 126.9%;
    border-radius: 50px;
    padding: 5px 14px;
    height: 28px;
    display: flex;
    align-items: center;
    &--active {
      color: $purple200;
      background: $navy300;
    }
    &--inactive {
      color: $navy300;
      background: #fafaff;
    }
  }
  &__call-to-action {
    display: flex;
    div:first-child {
      margin-right: 5px;
    }
  }
}

.edit-department-modal {
  .select__value-container {
    min-height: 48px;
    padding: 5px;
    height: auto;
  }
}