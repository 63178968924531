.empty-department {
  &__container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  &__content {
    max-width: 1240px;
    width: 100%;
    padding-top: 120px;
    @include mobile-only {
      padding-top: 40px;
    }
  }
  &__photo {
    display: flex;
    justify-content: center;
    svg {
      width: 80px;
      height: 90px;
    }
  }
  &__heading {
    text-align: center;
    color: $navy500;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 112%;
    letter-spacing: -0.2px; 
    margin-top: 20px;
  }
  &__button {
    margin-top: 64px !important;
    max-height: 40px !important;
    @include mobile-only {
      max-height: 48px !important;
      margin-top: 50px !important;
    }
  }
}