.task-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px 10px 20px;
  background-color: rgba(238, 237, 255, 1);
  border-radius: 8px;
  color: $navy400;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 126.9%; 
  max-width: 100%;
  margin: 8px 0;

  &__label {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 10px;
  }
  &__left {
    display: flex;
    max-width: 100%;
  }
  &__number {
    margin-right: 46px;
    @include mobile-only {
      display: none;
    }
  }
  &__right {
    display: flex;
    align-items: center;
  }
  &__icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: rgba(244, 244, 255, 1);
    border-radius: 4px;
    cursor: pointer;
    .svg {
      width: 20px;
      path {
        fill: $navy400;
      }
    }
  }
}