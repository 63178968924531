.contact {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  padding-bottom: 20px;
  &__title {
    margin-top: -10px;
  }
  &__container {
    height: 100%;
    z-index: 9;
    @include mobile-only {
      height: unset;
    }
  }
  &__paragraph {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    color: $navy400;
    text-align: center;
    font-family: Jost;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 126.9%;
    margin-top: 40px;
    margin-bottom: 50px;
  }
  &__form {
    max-width: 608px;
    width: 100%;
    margin: 0 auto;
    .checkbox__checkmark {
      border: unset !important;
      box-shadow: unset !important;
    }
  }
  &__form-top {
    display: flex;
    gap: 16px;
    @media(max-width: 700px) {
      flex-direction: column;
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .btn {
      max-width: 298px;
      max-height: 48px;
      @media(max-width: 700px) {
        max-width: 100%;
      }
    }
  }
  &--message .input {
    min-height: 269px;
  }
  &__terms {
    color: $navy400;
    font-family: Jost;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */ 
    margin-top: 10px;
    max-width: 533px;
  }
  &__checkbox {
    label {
      cursor: pointer;
    }
    .checkbox__checkmark {
      background: #EEEDFF;;
    }
  }
  &__send-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  &__send-content {
    max-width: 349px;
  }
  &__send-icon {
    font-size: 88px;
    margin-bottom: 20px;
    text-align: center;
  }
  &__send-header {
    color: $navy600;
    text-align: center;
    font-family: Jost;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 138%;
    margin-bottom: 20px;
  }
  &__send-paragraph {
    color: $navy600;
    text-align: center;
    font-family: Jost;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%;
    margin-bottom: 50px;
  }
  &__send-button {
    display: flex;
    justify-content: center;
    .btn {
      max-width: 296px;
      max-height: 48px;
    }
  }
}
.phone-cell {
  width: 100%;
  display: flex;
  position: relative;
  .select__wrapper {
    position: absolute;
    max-width: 140px;
    top: 2px;
    z-index: 9;
    @media(max-width: 700px) {
      top: -16px;
    }
  }
  &--number {
    input {
      margin-top: 18px;
      padding-left: 150px;
      @media(max-width: 700px) {
        margin-bottom: 30px;
        margin-top: 0px;
      }
    }
  }
  &--prefix {
    width: 140px;
    .select__control {
      height: 44px;
      margin-top: 1px;
      margin-left: 1px;
    }
    .svg {
      flex-shrink: 0;
    }
  }
}