@import './components/Header.scss';
@import './components/EmptyDepartment.scss';
@import './components/AddLesson.scss';
@import './components/LessonList.scss';
@import './components/LessonItem.scss';
@import './components/RemoveLesson.scss';
@import './components/TaskItem.scss';
@import './components/TaskList.scss';


.admin-department-page {
  &__container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    z-index: 9;
    background: #fafaff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &__content {
    max-width: 1240px;
    width: 100%;
    height: 100%;
    padding: 0 16px;
    @include mobile-only {
      padding: 0 16px 40px 16px;
    }
    
    .btn {
      margin: 0 auto;
      max-width: 192px;
      // margin-top: 0 !important;
      padding-top: 14px;
      padding-bottom: 14px;
    }
    &__top {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn {
        margin: 0;
        padding: 14px 40px;
        height: 40px;
      }
    }
  }
  &__content-header {
    color: $navy500;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 112%;
    letter-spacing: -0.2px; 
  }
 
}