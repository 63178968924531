@import './components/TopTowns.scss';

.statistic-page {
  .dashboard__title {
    margin-top: -6px;
  }
  .dashboard__container {
    display: flex;
    align-items: center;
  }
}