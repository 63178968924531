.svg-list {
  max-height: 100vh;
  width: 100vw;
  overflow: scroll;
  background-color: lightgray;
  padding-left: 50px;
  li {
    margin: 20px 0;
  }
  .svg {
    max-width: 100px;
  }
}