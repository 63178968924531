.btn {
  box-sizing: border-box;
  position: relative;
  border-radius: 6px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.005em;
  border: none;
  background-color: $purple500;
  color: $white;
  font-family: $primaryFont;
  font-weight: 700;
  gap: 8px;
  padding: 15px 20px 16px;
  cursor: pointer;
  width: 100%;
  transition: 0.3s;
  border: 3px solid transparent;
  &:hover {
    background-color: $purple600;
  }
  &:focus-visible {
    border: 3px solid #B0ADFE;
    outline: unset;
  }
  &--loading {
    background-color: $purple600;
    cursor: auto;
    position: relative;
    .loader {
      position: absolute;
      right: 10px;
    }
  }
  &--stroke {
    background-color: transparent;
    border: 2px solid $purple500;
    color: $purple500;
    &:hover {
      background-color: $purple300;
      color: $purple500;
    }
    &:focus-visible {
      color: $purple600;
      background-color: $purple400;
      border: 2px solid $purple600;
    }
  }
  &--disabled {
    background-color: $darkGray;
    pointer-events: none;
  }
  &--orange {
    background-color: #F2BC46;
    &:hover {
      background-color: #EAA818;
    }
    &:focus-visible {
      outline: none;
      border: 1px solid #B98002;
      background-color: #EAA818;
    }
  }
  &--stroke--orange {
    border: 2px solid #F2BC46;
    color: #F2BC46; 
    &:hover {
      color: rgba(188, 135, 0, 1);
      background-color: transparent;
      border: 2px solid rgba(188, 135, 0, 1);
    }
  }
  &--small {
    padding-top: 8.5px; 
    padding-bottom: 8.5px; 
  }
}
