.city-modal {
  h2 {
    color: $navy700;
    text-align: center;
    font-family: Jost;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 126.9%; /* 30.456px */
    letter-spacing: -0.24px; 
  }
  &--descript {
    color: $navy400;
    text-align: center;
    font-family: Jost;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */ 
    margin-bottom: 40px;
    align-self: stretch; 
    max-width: 400px;
  }
  .btn {
    max-height: 40px;
    margin-top: 50px;
  }
}